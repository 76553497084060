const Id = {
    agreement: [
        'I.	Definisi: ',
        'II. Ruang Lingkup Perjanjian: ',
        'III. Biaya Layanan dan Pajak: ',
        'IV. Hak dan Kewajiban Mitra Merchant: ',
        'V.	Hak dan Kewajiban Maxim: ',
        'VI. Garansi: ',
        'VII. Kerahasiaan: ',
        'VIII. Hak Kekayaan Intelektual: ',
        'IX. Force Majure: ',
    ],
    agreement_content: {
        1: [
            '1. “Perjanjian” adalah dokumen ini, yang menentukan cara pemberian hak untuk menggunakan Aplikasi Merchant, yang berisi informasi terkini tentang permintaan yang ada untuk jenis dan jumlah makanan dan minuman dan/atau belanjaan tertentu yang dipesan oleh Klien.',
            '2. “MAXIM” dalam konteks Perjanjian ini berarti PT Teknologi Perdana Indonesia dan Induk perusahaannya, atau pihak lain tertentu yang ditunjuk oleh PT Teknologi Perdana Indonesia dan/atau Induk perusahaannya, yang merupakan badan hukum yang memberikan hak kepada Mitra Merchant untuk menggunakan Aplikasi Merchant sesuai dengan perjanjian ini, dan yang berfokus pada penerimaan, pemrosesan, dan pengiriman pesanan Klien ke Mitra Merchant dan mengkomunikasikan status tindak lanjut Pesanan kepada Klien.',
            '3. "Mitra Merchant " berarti orang atau badan, yang telah menyatakan kepatuhannya terhadap Perjanjian dan telah menerima hak untuk menggunakan Aplikasi Merchant, dan oleh karena itu akan menikmati hak yang diberikan dan memenuhi tanggung jawab sebagaimana diatur dalam Perjanjian ini.',
            '4. “Klien” berarti seseorang yang melakukan Pemesanan untuk jenis dan jumlah makanan dan minuman dan/atau be-lanjaan melalui MAXIM.',
            "5. “Pesanan” adalah informasi atas permintaan dari Klien yang berisi jenis dan jumlah makanan dan minuman dan/atau bahan makanan tertentu yang akan disediakan oleh Mitra Merchant.",
            '6. “Aplikasi Merchant” berarti perangkat lunak komputer yang diinstal pada ponsel Mitra Merchant, yang ditujukan untuk memberikan akses jarak jauh ke Basis Data Pesanan kepada Mitra Merchant.',
            '7. “Aplikasi seluler” berarti perangkat lunak komputer yang diinstal pada ponsel Klien dan diintegrasikan ke dalam sistem informasi perangkat lunak MAXIM, yang memungkinkan komputerisasi proses pembuatan Pesanan melalui Internet.',
            '8. “Pengemudi” berarti Mitra MAXIM yang bertanggung jawab untuk mengantarkan makanan dan minuman dan/atau belanjaan yang dipesan oleh Klien.',
            '9. “Akun pribadi” berarti akun di mana pembayaran di muka dari Mitra Merchant dan jumlah uang tunai yang dipotong dari pembayaran ini sebagai pembayaran untuk memberikan akses untuk menggunakan Aplikasi Merchant. Akun pribadi memiliki nomor unik dan dibuat oleh MAXIM.',
            '10. “Saldo” berarti perbedaan pada titik waktu tertentu antara jumlah uang tunai yang disetorkan ke akun pribadi dan dipotong dari akun pribadi sampai titik waktu ini.'
        ],
        2: ['MAXIM dan Mitra Merchant dengan ini menyatakan kepatuhannya terhadap perjanjian dan kesediaannya untuk bekerja sama dalam rangka penyediaan makanan dan minuman dan/atau sembako untuk memenuhi kebutuhan Klien melalui pesanannya dari Aplikasi Seluler.'],
        3: [
            '1. Untuk setiap pesanan, MAXIM berhak menerima biaya yang harus dibayar dengan besaran persentase tertentu dari total harga pesanan sebelum pajak dan biaya layanan Mitra Merchant, yang akan dibebankan kepada Mitra Merchant langsung dari saldo akun pribadinya setelah menyelesaikan pesanan.',
            '2. Selama masa promosi dimana biaya yang harus dibayar adalah 0% (nol persen), Mitra Merchant tidak akan menetapkan harga makanan dan minuman dan/atau belanjaan yang lebih tinggi dari harga normal (harga untuk pembelian langsung dari toko/restoran Mitra Merchant).',
            '3. Mitra Merchant wajib menginformasikan kepada MAXIM jika hendak menambahkan pajak dan biaya layanan pada komponen harga pesanan.',
            '4. MAXIM berhak untuk mengubah persentase biaya yang dibayarkan setiap saat, dengan atau tanpa pemberitahuan sebelumnya kepada Mitra Merchant.',
            '5. Tanggung jawab masing-masing pihak sesuai peraturan perpajakan Republik Indonesia harus dipenuhi oleh masing-masing pihak. Tiap Pihak tidak bertanggung jawab atas pajak pihak lainnya.'
        ],
        4: [
            '1. Mitra Merchant berhak untuk:',
            'a. Menggunakan Aplikasi Merchant sesuai peruntukan aslinya.',
            'b. Mempromosikan toko/restoran miliknya melalui media-media yang tersedia.',
            'c. Bekerjasama dengan pihak lain tanpa mengganggu pelaksanaan kewajibannya berdasarkan Perjanjian ini.',
            'd. Mendapatkan dukungan teknis dari MAXIM jika diperlukan.',
            '2. Kewajiban Mitra Merchant:',
            'a. Menyelesaikan pesanan dengan sebaik-baiknya dalam waktu seefisien mungkin.',
            'b. Memberikan daftar produk yang dijual serta harganya kepada MAXIM.',
            'c. Memberikan alamat yang jelas serta titik lokasi (berdasarkan peta daring) dari toko/restoran miliknya.',
            'd. Memastikan bahwa toko/restoran miliknya dapat diakses/ditemukan/dijangkau dan dapat diidentifikasi oleh Pengemudi.',
            'e. Memenuhi kewajiban-kewajiban hukum guna menjalankan usaha di Indonesia.',
            'f. Memberikan data-data lain yang relevan, termasuk namun tidak terbatas pada data pribadi atau data perusahaan, kepada MAXIM jika diperlukan.',
            'g. Mengecek sendiri untuk memastikan ada tidaknya perubahan atau amandemen pada Perjanjian ini dan untuk memastikan perubahan diketahui.',
            '3. Mitra Merchant dilarang:',
            'a. Memberikan informasi palsu tentang produk dan harga. Tindakan seperti menjual produk dengan spesifikasi atau harga yang berbeda antara Aplikasi Merchant dengan Aplikasi Seluler dianggap sebagai pelanggaran terhadap aturan ini.',
            'b. Menjual produk berkualitas buruk, kadaluarsa, atau produk dalam kondisi buruk lain yang dapat mengakibatkan ketidaknyamanan atau ancaman bagi kesehatan Klien.',
            'c. Menjual barang ilegal berdasarkan hukum di Indonesia.',
            'd. Berinteraksi atau berkomunikasi dengan Klien atau Pengemudi secara tidak sopan, kasar, atau tidak profesional.',
            'e. Mencoba berinteraksi atau berkomunikasi dengan Klien atau Pengemudi untuk tujuan di luar pemenuhan pesanan.',
            'f. Menggunakan Aplikasi Merchant untuk keperluan di luar peruntukan aslinya.',
            'g. Mencoba meretas, memodifikasi, atau mengganggu stabilitas performa Aplikasi Merchant serta sistem yang terikat sebagai kesatuan padanya.'
        ],
        5: [
            '1. MAXIM berhak untuk:',
            'a. Mengubah ketentuan dalam Perjanjian ini kapanpun dan menentukan tangggal efektif perubahan tersebut.',
            'b. Menerima pembayaran biaya sesuai ketentuan Pasal III ayat (1) Perjanjian ini.',
            'c. Menghentikan atau mencabut akses Mitra Merchant terhadap Aplikasi Merchant jika Mitra Merchant melanggar kewajibannya sesuai ketentuan Pasal IV Perjanjian ini, atau apabila MAXIM menilai bahwa Mitra Merchant tidak dapat lagi menyediakan layanan.',
            'd. Meminta data dari Mitra Merchant jika diperlukan.',
            '2. MAXIM berkewajiban untuk:',
            'a. Memastikan Aplikasi Merchant serta sistem yang terikat sebagai kesatuan padanya berfungsi dengan baik dan dapat digunakan sesuai peruntukan aslinya.',
            'b. Memberikan bantuan teknis kepada Mitra Merchant jika diperlukan'
        ],
        6: [
            'Masing-masing Pihak menjamin:',
            'a. Bahwa Perjanjian ini disetujui oleh personil yang mempunyai kompetensi dan otorisasi atas nama MAXIM dan Mitra Merchant sesuai Anggaran Dasar masing-masing.',
            'b. Mitra Merchant telah memenuhi syarat dan kewajiban hukum untuk menjalankan ketentuan dalam Perjanjian ini.',
            'c. MAXIM menjamin bahwa pemilik merek “Maxim” serta usahanya adalah MAXIM sendiri, dan MAXIM membebaskan Mitra Merchant dari klaim pihak lain terkait sengketa merek atau kepemilikan bisnis.',
            'd. Masing-masing pihak tidak terlibat dalam kasus pidana atau perdata dan masing-masing Pihak membebaskan pihak lainnya dari klaim pihak ketiga akibat perbuatan hukumnya, terkait Perjanjian ini.'
        ],
        7: [
            '1. Para Pihak dengan ini sepakat untuk menjaga kerahasiaan atas segala informasi yang diperoleh masing-masing Pihak dari Pihak lainnya, yaitu meliputi namun tidak terbatas pada informasi penjualan, proses penyediaan, identitas pihak-pihak, dan informasi lain yang didapatkan oleh masing-masing pihak karena pelaksanaan Perjanjian ini yang merupakan rahasia dagang atau informasi rahasia lainnya.',
            '2. Kewajiban menjaga kerahasiaan atas informasi tersebut sebagaimana dimaksud dalam ayat (1). Pasal ini berlaku baik selama berlangsungnya Perjanjian ini maupun setelah berakhirnya Perjanjian ini ',
            '3. Ketentuan tersebut sebagaimana dimaksud dalam ayat (1) dan ayat (2) Pasal ini dapat dikesampingkan dalam hal pengungkapan informasi tersebut harus dilakukan dalam rangka penegakan hukum yang dilakukan oleh pihak yang berwenang berdasarkan peraturan perundang-undangan yang berlaku.'
        ],
        8: [
            '1. Para Pihak akan saling menjaga dan menghormati hak-hak Kekayaan Intelektual yang dimiliki atau dipegang oleh Para Pihak ataupun hak-hak Kekayaan Intelektual yang timbul sebagai akibat dari pelaksanaan Perjanjian ini, sesuai persyaratan dan ketentuan hukum yang berlaku.',
            '2. Dalam hal dilakukan promosi, Para Pihak saling mengizinkan penggunaan logo atau merek dagang yang dipergunakan dalam disain iklan promosi dan publikasi promosi,  tanpa mengabaikan ketentuan internal Para Pihak dan persyaratan dan ketentuan hukum yang berlaku.',
            '3. Perjanjian ini tidak mensyaratkan pemberian ataupun pengalihan hak eksklusif atas Kekayaan Intelektual antara Para Pihak, kecuali pada penggunaan logo atau merek dagang yang masing – masing dapat dipergunakan untuk kegiatan promosi sebagaimana yang dimaksud dalam ayat (2) Pasal ini.'
        ],
        9: [
            '1. Tidak dilaksanakannya atau tertundanya pelaksanaan sebagian atau keseluruhan ketentuan dari Perjanjian ni oleh salah satu Pihak atau Para Pihak tidak termasuk sebagai pelanggaran atas Perjanjian apabila hal tersebut disebabkan oleh adanya keadaan kahar (force majeure).',
            '2. Yang termasuk sebagai force majeure adalah kejadian-kejadian yang dengan segala daya dan upaya tidak dapat diduga dan tidak dapat diatasi oleh Pihak yang mengalaminya.',
            '3. Pihak yang tidak dapat memenuhi kewajibannya sehubungan dengan force majeure tersebut harus memberitahukan secara tertulis kepada Pihak lainnya selambat-lambatnya dalam waktu 14 (empat belas) hari kerja sejak mulainya kejadian tersebut. ',
            '4. Kelalaian atau keterlambatan Pihak yang terkena force majeure dalam memberitahukan sebagaimana dimaksud Pasal ini dapat mengakibatkan tidak diakuinya peristiwa dimaksud sebagai force majeure.'
        ],
    },
}

export default Id