import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '../../../misc/utils';
import { createSchedule, deleteSchedule, fetchSchedule, removeErrSchedule, updateSchedule } from '../../../application/action';

// Component
import { Language } from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'
import { LanguageComponent } from '../..'
import ReactLoading from 'react-loading'


// Assets
import BG from '../../../assets/image/bg-d_1.svg'
import SPLASH from '../../../assets/image/splash_1.svg'
import ScheduleCard from './ScheduleCard';
import { Modal } from 'react-bootstrap';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ModalAddComponent, ModalEditComponent } from './modalSchedule';
import './style.css';

const ScheduleScreen = (props) => {
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
    })
    const translate = localStorage.getItem('language')
    const dispatch = useDispatch()

    const token = new URLSearchParams(props.location.search).get('token')

    const [day, setDay] = React.useState()
    const [timeOpen, setTimeOpen] = React.useState('09:00')
    const [timeClose, setTimeClose] = React.useState('18:00')
    const [modalAdd, SetModalAdd] = React.useState(false)
    const [modalEdit, setModalEdit] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [editIndex, setEditIndex] = React.useState()
    const [options, setOptions] = React.useState([
        { value: 0, name: t.day.sunday },
        { value: 1, name: t.day.monday },
        { value: 2, name: t.day.tuesday },
        { value: 3, name: t.day.wednesday },
        { value: 4, name: t.day.thursday },
        { value: 5, name: t.day.friday },
        { value: 6, name: t.day.saturday }
    ])
    React.useEffect(() => {
        setLoading(true)
        dispatch(fetchSchedule(token))
    }, [])
    const { schedule, err } = useSelector((state) => {
        return {
            schedule: state.Schedule.item,
            err: state.Schedule.errors_message
        }
    })

    const onEdit = (index) => {
        let options = [
            { value: 0, name: t.day.sunday },
            { value: 1, name: t.day.monday },
            { value: 2, name: t.day.tuesday },
            { value: 3, name: t.day.wednesday },
            { value: 4, name: t.day.thursday },
            { value: 5, name: t.day.friday },
            { value: 6, name: t.day.saturday }
        ]
        if (schedule.length > 0) {
            options = options.filter((item) => !schedule.find((obj) => item.value === obj.day))
            options.push({
                value: schedule[index].day,
                name: schedule[index].day === 0 ? t.day.sunday : schedule[index].day === 1 ? t.day.monday : schedule[index].day === 2 ? t.day.tuesday :
                    schedule[index].day === 3 ? t.day.wednesday : schedule[index].day === 4 ? t.day.thursday : schedule[index].day === 5 ? t.day.friday :
                        schedule[index].day === 6 ? t.day.saturday : 'undifined'
            })
        }
        setOptions(options)
        setEditIndex(index)
        setDay(schedule[index].day)
        setTimeOpen(schedule[index].open)
        setTimeClose(schedule[index].close)
        setModalEdit(true)
    }
    const onAdd = () => {
        let options = [
            { value: 0, name: t.day.sunday },
            { value: 1, name: t.day.monday },
            { value: 2, name: t.day.tuesday },
            { value: 3, name: t.day.wednesday },
            { value: 4, name: t.day.thursday },
            { value: 5, name: t.day.friday },
            { value: 6, name: t.day.saturday }
        ]
        if (schedule.length > 0) {
            options = options.filter((item) => !schedule.find((obj) => item.value === obj.day))
        }
        setOptions(options)
        SetModalAdd(true)
    }
    const onChangeDay = (e) => {
        setDay(e.target.value)
    }
    const onChangeTime = (e, type) => {
        if (type === 'open') {
            setTimeOpen(e.target.value)
        }
        if (type === 'close') {
            setTimeClose(e.target.value)
        }
    }


    const handleSubmit = async (type) => {
        SetModalAdd(false)
        console.log(day, timeClose, timeOpen)
        if (day === undefined || !timeClose || !timeOpen) return alert(`can't upload schedule`)
        else {
            await setLoading(true)
            const data = {
                day: day,
                open: timeOpen,
                close: timeClose
            }
            if (type === 'submit') {
                const res = await createSchedule(token, data)
                if (res) {
                    await setLoading(false)
                    await window.location.reload()
                }
            }
            if (type === 'edit') {
                const res = await updateSchedule(token, data, schedule[editIndex].id)
                if (res) {
                    await setEditIndex()
                    await setLoading(false)
                    await window.location.reload()
                }
            }
        }
    }
    const handleDelete = async (index) => {
        await setLoading(true)
        const res = await deleteSchedule(token, schedule[index].id)
        if (res) {
            await setEditIndex()
            await setLoading(false)
            await window.location.reload()
        }
    }
    React.useEffect(() => {
        if (schedule !== null) {
           setOptions(options.filter((item) => !schedule.find((obj) => item.value === obj.day)))
           setLoading(false)
        }

    }, [schedule])
    return (
        <div>
            <div style={mobile ? style.containerMobile : style.containerDesktop}>
                <div className='translate'>
                    <Language className='translate-icon' />
                    <LanguageComponent value={translate} />
                </div>
                <div className='head-title-opening' >
                    <b>{t.title.openingHour}</b>
                </div>
                <div className='body-opening'>
                    {options.length > 0 &&<button onClick={onAdd} className='btn-body-opening'>{t.btn.schedule}</button>}
                    <hr />
                    {schedule?.length > 0 ? schedule.map((item, index) => {
                        return (
                            <ScheduleCard key={index} onDelete={handleDelete} idx={index} data={item} onEdit={onEdit} />
                        )
                    })
                        :
                        <>{t.err.nodata}</>
                    }
                    <hr />
                </div>
            </div>
            {mobile ? <></> : <div style={style.containerImageDesktop}>
                <img src={SPLASH} className='img-splash' />
            </div>}
            {/* Modal Add */}
            <ModalAddComponent
                modalAdd={modalAdd}
                onChangeDay={onChangeDay}
                options={options}
                timeOpen={timeOpen}
                timeClose={timeClose}
                onChangeTime={onChangeTime}
                SetModalAdd={SetModalAdd}
                handleSubmit={handleSubmit}
            />
            {/* Modal edit */}
            <ModalEditComponent
                modalEdit={modalEdit}
                onChangeDay={onChangeDay}
                options={options}
                timeOpen={timeOpen}
                timeClose={timeClose}
                onChangeTime={onChangeTime}
                setModalEdit={setModalEdit}
                day={day}
                handleSubmit={handleSubmit}
            />
            {/* Loading */}
            <Modal show={err} centered>
                <Modal.Body>
                    <div className='loading-react'>
                    <h1 style={{ fontSize: 30, fontWeight: 500, marginTop:15}}>{t.err.get}</h1>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn-modal' onClick={() => dispatch(removeErrSchedule(setLoading))}>{t.btn.Close}</button>
                </Modal.Footer>
            </Modal>
            <Modal show={loading} centered>
                <Modal.Body>
                    <div className='loading-react'>
                        <ReactLoading type='spin' color='red' height={70} width={70} />
                        <h1 style={style.loadingtext}>Loading ...</h1>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ScheduleScreen

const style = {
    containerDesktop: {
        float: 'left', width: '50%',
        margin: 'auto',
        padding: 30
    },
    containerMobile: {
        float: 'left', width: '100%',
        margin: 'auto',
        padding: 30
    },
    containerImageDesktop: {
        float: 'right', width: '50%', height: '100vh',
        backgroundImage: "url(" + BG + ")",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        right: 0
    },
    loadingtext:{
        fontSize: 30, fontWeight: 500, marginTop: 15, marginLeft: 20
    }
}