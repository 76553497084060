import { t } from "../../../misc/utils"

// Component
import { Navbar, Container, Nav } from "react-bootstrap"
import { useMediaQuery } from "react-responsive"
import { LanguageComponent } from '../../'
import { Language } from "@mui/icons-material"
const TopNavLanding = ({ pic, translate }) => {
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
    })
    const desktop_1920 = useMediaQuery({
        query: '(min-width:1920px)'
    })
    return (
        <>
            {mobile
                ?
                <Navbar expand='md' variant='dark' style={{ backgroundColor: 'transparent', width: '100%' }}>
                    <Container style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 10 }}>
                        <Navbar.Brand href="/" style={{ color: 'white' }}>
                            <img src={pic} style={{ width:100 }} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse className="justify-content-end"  >
                            <Nav style={{ alignItems: 'self-end'}}>
                                <Nav.Link href="register" style={{display:'block', marginLeft:'auto', color: 'white', fontWeight: 'bold', fontSize: 20}}>{t.btn.toRegist}</Nav.Link>
                                <div style={{ display:'flex', marginLeft:'auto' }}>
                                    {/* <Language style={{color:'white', margin: 10}} /> */}

                                    <LanguageComponent value={translate} style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', color: 'black', width: 80 }} />
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                :
                <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 90, marginRight: 90, paddingTop: 50 }}>
                    <div onClick={() => window.open('/', '_self')}>
                        <img src={pic} />
                    </div>
                    <div style={{ display: 'flex', }}>
                        <a href="register" style={{ width: 300, color: 'white', fontWeight: 'bold', fontSize: 20, textAlign: 'center', padding: 10, textDecoration:'none' }}>{t.btn.toRegist}</a>
                        {/* <Language style={{color:'white', margin: 10, marginTop:10}} /> */}
                        <LanguageComponent value={translate} style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', color: 'black', width: 80 }} />
                    </div>
                </div>
            }
        </>
    )
}

export default TopNavLanding