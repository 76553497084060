const Es = {
    // title
    title : {
        agreement: 'ACUERDO COMERCIAL',
        regis: 'REGISTRO DE COMERCIANTES',
        regok: 'REGISTRACIÓN EXITOSA',
        addok: 'PRODUCTO AÑADIDO CON ÉXITO',
        editok: 'CAMBIO DE PRODUCTO EXITOSO',
        product_add: 'AGREGAR PRODUCTO',
        product_edit: 'EDITAR PRODUCTO',
        landing: `Compra lo que quieras, te lo entregaremos!`,
        landingContent: '¿Por qué elegirnos?',
        openingHour: 'HORARIO DE APERTURA        ',
        modalAdd:'Agregar',
        modalEdit:'Editar'
    },
    
    // subtitle
    subTitle : {
        regis: 'EMPIEZA A VENDER TU PRODUCTO A TRAVÉS DEL SERVICIO MAXIM ORDEN',
        agreement: 'POR FAVOR LEA EL ACUERDO CUIDADOSAMENTE ANTES DE CONTINUAR', 
        detail: 'DETALLE DEL COMERCIANTE',
        location: 'DETALLE DE UBICACIÓN',
        pic: 'IMAGEN DEL COMERCIANTE',
        regok:'Gracias por unirse a Maxim Merchant y espere nuestra verificación. Puede acceder a la aplicación a través del siguiente enlace',
        addok:'Producto agregado. Puedes agregar más o puedes cerrar esta página',
        editok:'Producto editado. Puedes cerrar esta página',
        landing:'Precio justo del producto con entrega asequible',
    },
    
    // Form
    form : {
        name: 'Nombre de la tienda',
        desc: 'Descripción',
        email: 'Correo electrónico',
        password: 'La Contraseña',
        passconf: 'Confirmación De contraseña',
        phonenumber: 'Número De Teléfono',
        address: 'La Dirección',
        city: 'La Ciudad',
        district: 'Distritos',
        upload: 'Subir Imagen De La Tienda',
        product_name: 'Nombre Del Producto',
        product_category: 'Categoría',
        product_price: 'Precio',
        product_disc: 'Descuento',
        product_discamnt: 'Importe De Descuento',
        product_rec: 'Recomendado',
        day: 'Día',
        timeOpen: 'Tiempo abierto',
        timeClose: 'Hora de cierre',
    },
    
    // note
    note : {
        map: 'Amplíe el mapa para obtener una ubicación más precisa y toque/haga clic dos veces para la ubicación actual',
    },
    
    // button
    btn: {
        Register: 'Regístrate ahora',
        Language: 'Español',
        Next: 'Continuar',
        Submit: 'Presentar',
        Back: 'Previo',
        Acc: 'Acordar',
        Pic: 'Escoge Una Foto',
        Close: 'Cerrar',
        Addmore: 'Añadir Más',
        toRegist: 'Conviértase En Nuestro Socio',
        schedule: 'Agregar nuevo horario',
        edit: 'Editar',
        delete:'Borrar'
    },
    
    // text
    text: {
        welcome: 'Hola Bienvenido a Maxim Merchant',
        checkAgree: {
            agree: 'estás de acuerdo con',
            and: 'y',
            merchAgree: 'Acuerdo comercial',
            pvc: 'Política de Privacidad'
        },
        
        landing_content : {
            title : {
                deliver: 'Entrega Barata Y Rápida',
                price: 'Precio Mismo Que En La Tienda'
            },
            subTitle: {
                deliver:'Brindamos un servicio de entrega económico y rápido para garantizar que el cliente pueda obtener una experiencia de buena calidad a un servicio de bajo costo.',
                price:'Queremos proporcionar un ecosistema comercial justo para todos, damos a nuestros socios comerciales sin comisiones para asegurarnos de que el cliente pueda obtener el mismo precio que cuando compra directamente en la tienda.'
            }
        },
        landingFooter : {
            app: 'Haz tu pedido con Maxim',
            contact: 'Contacta con nosotros'
        },
        Tutorial_content : [
            {
                title: 'Elija el servicio Food en sus Maxim Apps',
                desc: [
                    'Abra su aplicación Maxim',
                    `Elija el servicio Food y elija “Foods&Goods”`,
                    `Pon la ubicación de destino`,
                    `Haga clic en "What to buy" o en el botón ORDER`
                ]
            },
            {
                title: 'Navegue y encuentre el comerciante al que desea comprar',
                desc: [
                    `Use la función de búsqueda para buscar el comerciante o el producto que desea comprar`,
                    `Elija "Near me" para ordenarlo por distancia desde el más cercano a su ubicación.`,
                    `O elija "By district" para buscar un comerciante en un distrito específico.`,
                ]
            },
            {
                title: 'Compra tus productos deseados y confirma el pedido',
                desc: [
                    `Haga clic en "Add" para poner el producto que desea comprar en su carrito`,
                    'Haga clic en "View my cart" después de terminar de elegir el producto.',
                    `Luego “Confirm Your Order”`,
                ]
            },
            {
                title: 'Verifique el estado de su pedido y rastree su pedido',
                desc: [
                    `Consulta el estado de tus pedidos y la respuesta de los comerciantes`,
                    `Haga clic en "Check Deliver Status" para ver el progreso de la entrega`
                ]
            },
            {
                title: 'Nuestro conductor recogerá y entregará su compra.',
                desc: [
                    `Nuestro conductor traerá su pedido con un servicio barato, bueno y rápido.`
                ]
            }
        ],
    },
    err: {
        notfilled: `El formulario no puede vaciarse`,
        notemail: `Dirección de correo electrónico no válida`,
        notpass: `La contraseña no es segura, tiene un mínimo de 7 caracteres e intenta combinar mayúsculas, minúsculas, números y caracteres especiales (!@#$%^&*_+=-)`,
        passnotsame: 'La contraseña y la confirmación de contraseña deben ser iguales',
        noloc: 'Elija su ubicación de los mapas (haga clic en el mapa dos veces para obtener su ubicación actual o mueva el puntero a su ubicación)' ,
        nopic: 'Elige la foto para subir',
        sent: 'Error al enviar datos al servidor',
        emailsent: 'Correo electrónico ya registrado',
        picFail: `No se puede cargar la imagen, use otra foto`,
        nodata: 'No hay datos',
        get: 'Error al obtener datos'
    },
    option : {
        yes: "Sí",
        no: "No"
    },
    day :{
        sunday: 'domingo',
        monday: 'lunes',
        tuesday: 'martes',
        wednesday: 'miércoles',
        thursday: 'jueves',
        friday: 'viernes',
        saturday: 'sábado',
    }

}

export default Es