import Routers from './application/router';
import React from 'react'
import ReactGa from 'react-ga'

function App() {
  const language = localStorage.getItem('language')
  if(!language) localStorage.setItem('language', 'pt')
  

  // # production
  
  React.useEffect(() => {
    ReactGa.initialize('UA-208000231-1')
    ReactGa.pageview(window.location.pathname)
  },[])


  // # testing
  
  // React.useEffect(() => {
  //   ReactGa.initialize('UA-219754999-1')
  //   ReactGa.pageview(window.location.pathname)
  // },[])
  return (
    <div>
      <Routers />
    </div>
  );
}

export default App;
