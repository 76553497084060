import React from 'react'
import { useSelector } from 'react-redux'
import RegisterMerchantDetail from './RegisterMerchantDetail'
import RegisterDone from './RegisterMerchantDone'
import RegisterMerchantLocation from './RegisterMerchantLocation'
import RegisterMerchantPic from './RegisterMerchantPic'
const RegisterPage = () => {
    const {page} = useSelector((state) => {
        return{
            page: state.Register.page
        }
    })

    return(
        <div>
            {page === 1 ? 
                <RegisterMerchantDetail /> :
                page === 2 ? <RegisterMerchantLocation /> :
                page === 3 ? <RegisterMerchantPic /> : 
                page === 4 ? <RegisterDone /> : <div></div>
            }
        </div>
    )
}

export default RegisterPage