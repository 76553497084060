const En = {
    // title
    title : {
        agreement: 'MERCHANT AGREEMENT',
        regis: 'MERCHANT REGISTRATION',
        regok: 'REGISTRATION SUCCESS',
        addok: 'ADD PRODUCT SUCCESS',
        editok: 'EDIT PRODUCT SUCCESS',
        product_add: 'ADD PRODUCT',
        product_edit: 'EDIT PRODUCT',
        landing: `Buy whatever you want, we will deliver it!`,
        landingContent: 'Why Choose Us?',
        openingHour: 'OPENING HOURS',
        modalAdd:'Add',
        modalEdit:'Edit'
    },
    
    // subtitle
    subTitle : {
        regis: 'START SELLING YOUR PRODUCT THROUGH MAXIM ORDER SERVICE',
        agreement: 'PLEASE READ THE AGREEMENT CAREFULLY BEFORE CONTINUING', 
        detail: 'MERCHANT DETAIL',
        location: 'LOCATION DETAIL',
        pic: 'MERCHANT PICTURE',
        regok:'Thank you for joining Maxim Merchant and please wait for verification from us. You can access the application through the link below.',
        addok:'Product added. You can add more or you can close this page',
        editok:'Product edited. You can close this page',
        landing:'Fair product price with affordable delivery',
    },
    
    // Form
    form : {
        name: 'Store Name',
        desc: 'Description',
        email: 'Email',
        password: 'Password',
        passconf: 'Password Confirmation',
        phonenumber: 'Phone Number',
        address: 'Address',
        city: 'City',
        district: 'District',
        upload: 'Upload Store Picture',
        product_name: 'Product Name',
        product_category: 'Category',
        product_price: 'Price',
        product_disc: 'Discount',
        product_discamnt: 'Discount Amount',
        product_rec: 'Recommended',
        day: 'Day',
        timeOpen: 'Time Open',
        timeClose: 'Time Close',
    },
    
    // note
    note : {
        map: 'Enlarge map for more accurate location, and tap/click twice for current location',
    },
    
    // button
    btn: {
        Register: 'Register Now',
        Language: 'English',
        Next: 'Continue',
        Submit: 'Submit',
        Back: 'Back',
        Acc: 'Agree',
        Pic: 'Choose Photo',
        Close: 'Close',
        Addmore: 'Add More',
        toRegist: 'Become Our Partner',
        schedule: 'Add New Schedule',
        edit: 'Edit',
        delete:'Delete'
    },
    
    // text
    text: {
        welcome: 'Hello Welcome to Maxim Merchant',
        checkAgree: {
            agree: 'You Agree With',
            and: 'and',
            merchAgree: 'Merchant Agreement',
            pvc: 'Privacy Policy'
        },
        
        landing_content : {
            title : {
                deliver: 'Affordable and Fast Delivery',
                price: 'Same Price as in the Store'
            },
            subTitle: {
                deliver:'We provide affordable and fast delivery service to ensure that the client can get good quality experience at the low price service.',
                price:'We aim to provide our users with a fair business ecosystem, so we give our merchant partners zero commission to ensure the customer can get same price as if buying directly at the store.'
            }
        },
        landingFooter : {
            app: 'Order with Maxim',
            contact: 'Contact Us'
        },
        Tutorial_content : [
            {
                title: 'Choose the Food service in your Maxim Apps',
                desc: [
                    'Open your Maxim app',
                    `Choose the Food service and choose “Foods&Goods”`,
                    `Enter the destination location`,
                    `Tap “What to buy” or the ORDER button`
                ]
            },
            {
                title: 'Browse and find the merchant you want to buy from',
                desc: [
                    `Use search to find the required merchant or item you want to buy`,
                    `Choose "Nearest" to sort offers by distance starting from the one nearest to you`,
                    `Choose "By Location" to look for a merchant in a specific location`,
                ]
            },
            {
                title: 'Purchase your desired products and confirm the order',
                desc: [
                    `Tap “Add” to put the product you want to buy in to your cart`,
                    'Tap “View my cart” after you finish choosing the product.',
                    `Then tap “Confirm your order”`,
                ]
            },
            {
                title: 'Check your order status & track your order',
                desc: [
                    `See the status of your orders and the response from the merchants`,
                    `Tap “Check Delivery Status” to see the delivery progress`
                ]
            },
            {
                title: 'Our driver will pick & deliver your purchase.',
                desc: [
                    `Our driver will bring your order. Our service is good, cheap and fast.`
                ]
            }
        ],
    },
    err: {
        notfilled: `Form can't be empty`,
        notemail: `Email address not valid`,
        notpass: `Password is not strong, minimal 7 character and try to combine uppercase, lowercase, number, and special character (!@#$%^&*_+=-)`,
        passnotsame: 'Password and Password Confirmation must same',
        noloc: 'Pick your location from the maps (click the map twice to get your current location or move the pointer to your location)' ,
        nopic: 'Choose photo to upload',
        sent: 'Error While Sending Data To Server',
        emailsent: 'Email already Registered',
        picFail: `Can't upload image please use another photo`,
        nodata: 'There is no Data',
        get: 'Error While Getting Data'
    },
    option : {
        yes: "Yes",
        no: "No"
    },
    day :{
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
    }

}

export default En