import Compressor from "compressorjs";
import { En, Es, Id, My, Ph, Pt, Th, Ch } from "../application/language";
import { PvcEn, PvcId } from "./privacypolicy";
import { AgreementEn, AgreementId } from "./agreement";
const translate = localStorage.getItem('language')


export const convertUTCToLocalTime = dateString => {
    let date = new Date(dateString);
    return (
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) +
      '/' +
      (date.getMonth() < 9 ? '0' + parseInt(parseInt(date.getMonth()) + 1) : date.getMonth() + 1) +
      '/' +
      date.getFullYear() +
      ' ' +
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':' +
      (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
    )
  };

export const process_image = async(index, data, typeProcess) => {
  try{
      const response = await Promise.all(index.map(async(item) => {
          
          const compress = await new Promise((resolve, reject) => {
              new Compressor(data[item], {
                  quality: 0.6, 
                  convertSize:500000,
                  success: async(result) => {
                      resolve(result)
                  }
              })
          })

          switch (typeProcess) {
              case "IMG_PROCESSING":
                  return compress
              case "CREATE_URL":
                  return URL.createObjectURL(data[item])
              default:
                  break;
          }
          
         
      }))
      return response

  }
  catch(err){
      console.log(err)
  }
}

export const process_image_crop = async(index, data, typeProcess) => {
    try{
        const response = await Promise.all(index.map(async(item) => {
            
            const compress = await new Promise((resolve, reject) => {
                new Compressor(data, {
                    quality: 0.6, 
                    convertSize:500000,
                    success: async(result) => {
                        resolve(result)
                    }
                })
            })
  
            switch (typeProcess) {
                case "IMG_PROCESSING":
                    return compress
                case "CREATE_URL":
                    return URL.createObjectURL(data)
                default:
                    break;
            }
            
           
        }))
        return response
  
    }
    catch(err){
        console.log(err)
    }
  }
  

export const t = translate === 'es' ? Es : translate === 'id' ? Id : translate === 'my' ? My : translate === 'ph' ? Ph : translate === 'pt' ? Pt : translate === 'th' ? Th : translate === 'ch' ? Ch : En 
export const t_pvc = translate === 'es' ? PvcEn : translate === 'id' ? PvcId : translate === 'my' ? PvcEn : translate === 'ph' ? PvcEn : translate === 'pt' ? PvcEn : translate === 'th' ? PvcEn : translate === 'ch' ? PvcEn : PvcEn 
export const t_agree = translate === 'es' ? AgreementEn : translate === 'id' ? AgreementId : translate === 'my' ? AgreementEn : translate === 'ph' ? AgreementEn : translate === 'pt' ? AgreementEn : translate === 'th' ? AgreementEn : translate === 'ch' ? AgreementEn : AgreementEn 
