const Pt = {
    // title
    title : {
        agreement: 'ACORDO DE COMERCIANTE',
        regis: 'REGISTRO DE COMERCIANTE',
        regok: 'SUCESSO NO REGISTRO',
        addok: 'ADICIONAR SUCESSO DO PRODUTO',
        editok: 'EDITAR SUCESSO DO PRODUTO',
        product_add: 'ADICIONAR PRODUTO',
        product_edit: 'EDITAR PRODUTO',
        landing: `Compre o que você quer, vamos entregar!`,
        landingContent: 'Porque escolher-nos?',
        openingHour: 'HORÁRIO DE FUNCIONAMENTO',
        modalAdd:'Adicionar',
        modalEdit:'Editar'
    },
    
    // subtitle
    subTitle : {
        regis: 'COMECE A VENDER SEU PRODUTO ATRAVÉS DO SERVIÇO MAXIM ORDER',
        agreement: 'POR FAVOR, LEIA ATENTAMENTE O ACORDO ANTES DE CONTINUAR', 
        detail: 'DETALHE DO COMERCIANTE',
        location: 'DETALHE DA LOCALIZAÇÃO',
        pic: 'IMAGEM DO COMERCIANTE.',
        regok:'Obrigado por se juntar a Maxim Merchant e aguarde a verificação de nós. Você pode acessar o aplicativo através do link abaixo',
        addok:'Produto adicionado. Você pode adicionar mais ou fechar esta página',
        editok:'Produto editado. Você pode fechar esta página',
        landing:'Preço do produto justo com entrega acessível',
    },
    
    // Form
    form : {
        name: 'Nome da loja',
        desc: 'Descrição',
        email: 'E-mail',
        password: 'Senha',
        passconf: 'ConfirmaÇão Da Senha',
        phonenumber: 'Número de telefone',
        address: 'Endereço',
        city: 'Cidade',
        district: 'Distritos',
        upload: 'Carregar imagem da loja.',
        product_name: 'Nome do Produto',
        product_category: 'Categoria',
        product_price: 'Preço',
        product_disc: 'Desconto',
        product_discamnt: 'Quantidade de desconto',
        product_rec: 'Recomendado',
        day: 'Dia',
        timeOpen: 'Horário aberto',
        timeClose: 'Tempo de fechamento',
    },
    
    // note
    note : {
        map: 'Aumentar o mapa para localização mais precisa, e toque em / clique duas vezes para localização atual',
    },
    
    // button
    btn: {
        Register: 'Registrar agora',
        Language: 'Português',
        Next: 'Continuar',
        Submit: 'Enviar',
        Back: 'Voltar',
        Acc: 'Concordar',
        Pic: 'Escolher Foto',
        Close: 'Fechar',
        Addmore: 'Adicione mais',
        toRegist: 'Torne-se nosso parceiro',
        schedule: 'Adicionar nova programação',
        edit: 'Editar',
        delete:'Excluir'
    },
    
    // text
    text: {
        welcome: 'Olá bem-vindo ao Maxim Merchant',
        checkAgree: {
            agree: 'Você concorda com',
            and: 'e',
            merchAgree: 'Contrato Comercial',
            pvc: 'Política de privacidade'
        },
        
        landing_content : {
            title : {
                deliver: 'Entrega barata e rápida',
                price: 'Preço mesmo que na loja'
            },
            subTitle: {
                deliver:'Nós fornecemos serviço de entrega barato e rápido para garantir que o cliente possa obter uma boa experiência de qualidade no serviço de baixo custo.',
                price:'Queremos fornecer o Fair Business Ecossistema a todos, damos aos nossos parceiros comerciais comission-free para insegurar o cliente pode obter preço como o mesmo que quando comprar diretamente para a loja'
            }
        },
        landingFooter : {
            app: 'Ordem com máxima',
            contact: 'Contate-Nos'
        },
        Tutorial_content : [
            {
                title: 'Escolha o serviço Food em seus aplicativos Maxim',
                desc: [
                    'Abra seu aplicativo Maxim',
                    `Escolha o serviço Food e escolha “Foods&Goods”`,
                    `Coloque o local de destino`,
                    `Clique em "What to Buy" ou o botão do pedido`
                ]
            },
            {
                title: 'Navegue e encontre o comerciante que você quer comprar de',
                desc: [
                    `Use o recurso de pesquisa para pesquisar o comerciante ou produto que você deseja comprar`,
                    `Escolha "Near Me" para classificá-lo por partida de distância do mais próximo da sua localização.`,
                    `Ou escolha "By District" para procurar o comerciante em distrito específico.`,
                ]
            },
            {
                title: 'Compre seus produtos desejados e confirme o pedido',
                desc: [
                    `Clique em "Add" para colocar o produto que você deseja comprar no seu carrinho`,
                    'Clique em "View my cart" após terminar de escolher o produto.',
                    `Então “Confirm your order”`,
                ]
            },
            {
                title: 'Verifique o status do seu pedido e acompanhe seu pedido',
                desc: [
                    `Veja o status de suas ordens e a resposta dos comerciantes`,
                    `Clique em "Check Delivery Status" para ver o progresso da entrega`
                ]
            },
            {
                title: 'Nosso motorista vai pegar e entregar sua compra.',
                desc: [
                    `Nosso motorista trará seu pedido com serviço barato, bom e rápido.`
                ]
            }
        ],
    },
    err: {
        notfilled: `Forma não pode vazar`,
        notemail: `Endereço de email inválido`,
        notpass: `A senha não é forte, mínima de 7 caracteres e tenta combinar maiúsculas, minúsculas, número e caractere especial (! @ # $% ^ & * _ + = -)`,
        passnotsame: 'A confirmação de senha e senha deve',
        noloc: 'Escolha sua localização nos mapas (clique no mapa duas vezes para obter sua localização atual ou mover o ponteiro para a sua localização)' ,
        nopic: 'Escolha a foto para upload',
        sent: 'Erro ao enviar dados ao servidor',
        emailsent: 'E-mail já registrado',
        picFail: `Não é possível fazer upload da imagem, use outra foto`,
        nodata: 'Não há dados',
        get: 'Erro ao obter dados'
    },
    option : {
        yes: "sim",
        no: "Não"
    },
    day :{
        sunday: 'Domingo',
        monday: 'segunda-feira',
        tuesday: 'terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'quinta-feira',
        friday: 'sexta-feira',
        saturday: 'Sábado',
    }

}

export default Pt