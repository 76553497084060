// Component
import { Carousel } from 'react-bootstrap'

// Assets
import Phone1 from '../../../assets/image/phone1.png'
import Phone2 from '../../../assets/image/phone2.png'
import Phone3 from '../../../assets/image/phone3.png'
import Phone4 from '../../../assets/image/phone4.png'
import Phone5 from '../../../assets/image/phone5.png'
import idPhone1 from '../../../assets/image/id-phone1.png'
import idPhone2 from '../../../assets/image/id-phone2.png'
import idPhone3 from '../../../assets/image/id-phone3.png'
import idPhone4 from '../../../assets/image/id-phone4.png'
import idPhone5 from '../../../assets/image/id-phone5.png'

const Phone = ({ activeKey, translate, changesButton }) => {
    const EnImage = [Phone1, Phone2, Phone3, Phone4, Phone5]
    const IdImage = [idPhone1, idPhone2, idPhone3, idPhone4, idPhone5]
    return (
        <>
            <Carousel style={{ height: 580, width: 280 }} activeIndex={activeKey} controls={true} onSelect={(e) => changesButton(e)} interval={null}>
                {
                    translate === 'id' ?
                        IdImage.map((item, index) => {
                            // console.log(item)
                            return (
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={item}
                                        alt="First slide"
                                        style={{ height: 580, width: 280 }}
                                    />
                                </Carousel.Item>
                            )
                        })
                        :
                        EnImage.map((item, index) => {
                            return (
                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={item}
                                        alt="First slide"
                                        style={{ height: 580, width: 280 }}
                                    />
                                </Carousel.Item>
                            )
                        })
                }

            </Carousel>
        </>
    )
}

export default Phone