import { Modal } from 'react-bootstrap';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { t } from '../../../misc/utils';
import './style.css';

export const ModalAddComponent = ({modalAdd, onChangeDay, options, timeOpen, timeClose, onChangeTime, SetModalAdd, handleSubmit}) => {
    return (
        <Modal show={modalAdd} centered>    
            <Modal.Body>
                <h5>{t.title.modalAdd}</h5>
                <hr />
                {/* <InputLabel>Days</InputLabel> */}

                <FormControl fullWidth>
                    <InputLabel>{t.form.day}</InputLabel>
                    <Select
                        label={t.form.day}
                        onChange={onChangeDay}
                    >
                        {options.map((item) => {
                            return (
                                <MenuItem value={item.value}>{item.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div class="row">
                    <div class="col-md-6">
                        <InputLabel>{t.form.timeOpen}</InputLabel>
                        <input class='form-control' type='time' value={timeOpen} onChange={(e) => onChangeTime(e, 'open')} />
                    </div>
                    <div class="col-md-6">
                        <InputLabel>{t.form.timeClose}</InputLabel>
                        <input class='form-control' type='time' value={timeClose} onChange={(e) => onChangeTime(e, 'close')} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn-modal' onClick={() => SetModalAdd(false)}>{t.btn.Close}</button>
                <button className='btn-modal' onClick={() => handleSubmit('submit')}>{t.btn.Submit}</button>
            </Modal.Footer>
        </Modal>
    )
}

export const ModalEditComponent = ({modalEdit, onChangeDay, day, options, timeOpen, timeClose, onChangeTime, setModalEdit, handleSubmit}) => {
    return (
        <Modal show={modalEdit} centered>
            <Modal.Body>
                <h5>{t.title.modalEdit}</h5>
                <hr />
                <FormControl fullWidth>
                    <InputLabel>{t.form.day}</InputLabel>
                    <Select
                        label={t.form.day}
                        onChange={onChangeDay}
                        value={day}
                    >
                        {options.map((item) => {
                            return (
                                <MenuItem value={item.value}>{item.name}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div class='row'>
                    <div class="col-md-6">
                        <InputLabel>{t.form.timeOpen}</InputLabel>
                        <input class='form-control' type='time' value={timeOpen} onChange={(e) => onChangeTime(e, 'open')} />
                    </div>
                    <div class="col-md-6">
                        <InputLabel>{t.form.timeClose}</InputLabel>
                        <input class='form-control' type='time' value={timeClose} onChange={(e) => onChangeTime(e, 'close')} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn-modal' onClick={() => setModalEdit(false)}>{t.btn.Close}</button>
                <button className='btn-modal' onClick={() => handleSubmit('edit')}>{t.btn.Submit}</button>
            </Modal.Footer>
        </Modal>
    )
}