const Ph = {
    // title
    title : {
        agreement: 'KASUNDUAN SA MERCHANT.',
        regis: 'PAGPAPAREHISTRO NG MERCHANT.',
        regok: 'TAGUMPAY SA PAGPAPAREHISTRO',
        addok: 'MAGDAGDAG NG TAGUMPAY NG PRODUKTO',
        editok: 'I-EDIT ANG TAGUMPAY NG PRODUKTO',
        product_add: 'MAGDAGDAG NG PRODUKTO',
        product_edit: 'I-EDIT ANG PRODUKTO',
        landing: `Bilhin ang gusto mo, ibibigay namin!`,
        landingContent: 'Bakit pumili sa amin?',
        openingHour: 'ORAS NG PAGBUBUKAS',
        modalAdd:'Idagdag',
        modalEdit:'I-edit'
    },
    
    // subtitle
    subTitle : {
        regis: 'SIMULAN ANG PAGBEBENTA NG IYONG PRODUKTO SA PAMAMAGITAN NG SERBISYO NG ORDER NG MAXIM',
        agreement: 'MANGYARING BASAHIN NANG MAINGAT ANG KASUNDUAN BAGO MAGPATULOY', 
        detail: 'DETALYE NG MERCHANT.',
        location: 'DETALYE NG LOKASYON.',
        pic: 'LARAWAN NG MERCHANT.',
        regok:'Salamat sa pagsali sa maxim merchant at mangyaring maghintay para sa pag-verify mula sa amin. Maaari mong ma-access ang application sa pamamagitan ng link sa ibaba',
        addok:'Idinagdag ang produkto. Maaari kang magdagdag ng higit pa o maaari mong isara ang pahinang ito',
        editok:'Na-edit ang produkto. Maaari mong isara ang pahinang ito',
        landing:'Fair Price Price na may abot-kayang paghahatid.',
    },
    
    // Form
    form : {
        name: 'Pangalan ng tindahan',
        desc: 'Paglalarawan.',
        email: 'Email',
        password: 'Password',
        passconf: 'Pagkumpirma ng password',
        phonenumber: 'Numero ng telepono',
        address: 'Address',
        city: 'Lungsod',
        district: 'Mga Distrito',
        upload: 'I-upload ang larawan ng larawan',
        product_name: 'pangalan ng Produkto',
        product_category: 'Kategorya',
        product_price: 'Presyo',
        product_disc: 'Diskwento',
        product_discamnt: 'Halaga ng diskwento',
        product_rec: 'Inirerekomenda',
        day: 'Araw',
        timeOpen: 'Oras Bukas',
        timeClose: 'Oras Close',
    },
    
    // note
    note : {
        map: 'Palakihin ang mapa para sa mas tumpak na lokasyon, at tapikin / i-click nang dalawang beses para sa kasalukuyang lokasyon',
    },
    
    // button
    btn: {
        Register: 'Mag-rehistro na ngayon',
        Language: 'Tagalog',
        Next: 'Magpatuloy',
        Submit: 'Ipasa',
        Back: 'Pabalik',
        Acc: 'Sumang-ayon',
        Pic: 'Pumili ng larawan',
        Close: 'Malapit',
        Addmore: 'Magdagdag pa',
        toRegist: 'Maging kasosyo',
        schedule: 'Magdagdag ng Bagong Iskedyul',
        edit: 'I-edit',
        delete:'Tanggalin'
    },
    
    // text
    text: {
        welcome: 'Kamusta Maligayang pagdating sa Maxim Merchant',
        checkAgree: {
            agree: 'Sumasang-ayon ka sa',
            and: 'at',
            merchAgree: 'Kasunduan sa Merchant',
            pvc: 'Patakaran sa Privacy'
        },
        
        landing_content : {
            title : {
                deliver: 'Murang at mabilis na paghahatid',
                price: 'Presyo na katulad ng sa tindahan'
            },
            subTitle: {
                deliver:'Nagbibigay kami ng murang at mabilis na serbisyo sa paghahatid upang matiyak na ang kliyente ay maaaring makakuha ng mahusay na karanasan sa kalidad sa mababang gastos sa serbisyo.',
                price:'Gusto naming magbigay ng patas na ecosystem ng negosyo sa lahat, binibigyan namin ang aming mga kasosyo sa merchant-free upang hindi sigurado ang customer ay maaaring makakuha ng presyo bilang kapareho ng kapag bumili nang direkta sa tindahan'
            }
        },
        landingFooter : {
            app: 'Order sa maxim.',
            contact: 'Makipag-ugnayan sa amin'
        },
        Tutorial_content : [
            {
                title: 'Piliin ang serbisyo ng pagkain sa iyong masaganang apps.',
                desc: [
                    'Buksan ang iyong Maxim App.',
                    `Piliin ang serbisyo ng pagkain at piliin ang "Foods&Goods"`,
                    `Ilagay ang lokasyon ng patutunguhan`,
                    `I-click ang "What to buy" o ang pindutan ng ORDER`
                ]
            },
            {
                title: 'Mag-browse at hanapin ang merchant na gusto mong bilhin mula sa',
                desc: [
                    `Gumamit ng tampok sa paghahanap upang maghanap para sa merchant o produkto na gusto mong bilhin`,
                    `Piliin ang "Near Me" upang pag-uri-uriin ito sa pamamagitan ng distansya mula sa pinakamalapit sa iyong lokasyon.`,
                    `O piliin ang "sa pamamagitan ng distrito" upang maghanap ng merchant sa partikular na distrito.`,
                ]
            },
            {
                title: 'Bumili ng iyong nais na mga produkto at kumpirmahin ang order.',
                desc: [
                    `I-click ang "Add" upang ilagay ang produkto na gusto mong bilhin sa iyong cart`,
                    'I-click ang "View My Cart" pagkatapos tapusin ang pagpili ng produkto.',
                    `Pagkatapos ay “Confirm your order”`,
                ]
            },
            {
                title: 'Suriin ang katayuan ng iyong order at mga track ang iyong order.',
                desc: [
                    `Tingnan ang katayuan ng iyong mga order at ang tugon mula sa mga merchant`,
                    `I-click ang "Check Delivery Status" upang makita ang pag-unlad ng paghahatid`
                ]
            },
            {
                title: 'Ang aming driver ay kukunin at ihatid ang iyong binili.',
                desc: [
                    `Dadalhin ng aming driver ang iyong order sa murang, mabuti at mabilis na serbisyo.`
                ]
            }
        ],
    },
    err: {
        notfilled: `Hindi maaaring walang laman ang form`,
        notemail: `Hindi wasto ang email address`,
        notpass: `Ang password ay hindi malakas, minimal 7 character at subukan upang pagsamahin ang uppercase, lowercase, numero, at espesyal na character (! @ # $% ^ & * _ + = -)`,
        passnotsame: 'Dapat na pareho ang pagkumpirma ng password at password',
        noloc: 'Piliin ang iyong lokasyon mula sa mga mapa (i-click ang mapa nang dalawang beses upang makuha ang iyong kasalukuyang lokasyon o ilipat ang pointer sa iyong lokasyon)' ,
        nopic: 'Pumili ng larawan sa pag-upload',
        sent: 'Error habang nagpapadala ng data sa server',
        emailsent: 'Nakarehistro na ang email',
        picFail: `Hindi makapag-upload ng larawan, mangyaring gumamit ng ibang larawan`,
        nodata: 'Walang data',
        get: 'Error Habang Kumukuha ng Data'
    },
    option : {
        yes: "OO",
        no: "Hindi"
    },
    day :{
        sunday: 'Linggo',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miyerkules',
        thursday: 'Huwebes',
        friday: 'Biyernes',
        saturday: 'Sabado',
    }

}

export default Ph