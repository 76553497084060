const Th = {
    // title
    title : {
        agreement: 'ข้อตกลงการค้า',
        regis: 'การลงทะเบียนร้านค้า',
        regok: 'ความสำเร็จในการลงทะเบียน',
        addok: 'เพิ่มความสำเร็จของผลิคภัณฑ์',
        editok: 'แก้ไขความสำเร็จของผลิตภัณฑ์',
        product_add: 'เพิ่มผลิตภัณฑ์',
        product_edit: 'แก้ไขผลิตภัณฑ์',
        landing: `ซื้อสิ่งที่คุณต้องการ,พวกเราจัดส่งให้!`,
        landingContent: 'ทำไมถึงเลือกพวกเรา',
        openingHour: 'เวลาทำการ',
        modalAdd:'เพิ่ม',
        modalEdit:'แก้ไข'
    },
    
    // subtitle
    subTitle : {
        regis: 'เริ่มขายผลิตภัณฑ์ของคุณผ่านบริการแม็กซิม ออเดอร์',
        agreement: 'โปรดอ่านข้อตกลงโดยละเอียดก่อนดำเนินการต่อ', 
        detail: 'รายละเอียดร้านค้า',
        location: 'รายละเอียดตำแหน่งที่ตั้ง',
        pic: 'รูปถ่ายร้านค้า',
        regok:'ขอบคุณสำหรับการเข้าร่วมเป็นร้านค้าแม็กซิม และโปรดรอการยืนยันจากเรา คุณสามารถเข้าถึงแอพพลิเคชั่นผ่านลิงค์ด้านล่าง',
        addok:'เพิ่มผลิตภัณฑ์แล้ว คุณสามารถเพิ่มเพิ่มเติมหรือคุณสามารถปิดหน้านี้',
        editok:'แก้ไขผลิตภัณฑ์แล้ว คุณสามารถปิดหน้านี้',
        landing:'สินค้าราคายุติธรรมพร้อมการจัดส่งที่เหมาะสม',
    },
    
    // Form
    form : {
        name: 'ชื่อร้านค้า',
        desc: 'คำบรรยาย',
        email: 'อีเมล',
        password: 'รหัสผ่าน',
        passconf: 'ยืนยันรหัสผ่าน',
        phonenumber: 'เบอร์โทรศัพท์',
        address: 'ที่อยู่',
        city: 'เมือง',
        district: 'อำเภอ',
        upload: 'อัปโหลดรูปภาพของร้านค้า',
        product_name: 'ชื่่อผลิตภัณฑ์',
        product_category: 'ประเภท',
        product_price: 'ราคา',
        product_disc: 'ส่วนลด',
        product_discamnt: 'ยอดรวมส่วนลด',
        product_rec: 'แนะนำ',
        day: 'วัน',
        timeOpen: 'เวลาเปิด',
        timeClose: 'เวลาปิด',
    },
    
    // note
    note : {
        map: 'ขยายแผนที่สําหรับตําแหน่งที่แม่นยํายิ่งขึ้น และแตะ/คลิกสองครั้งสําหรับตําแหน่งปัจจุบัน',
    },
    
    // button
    btn: {
        Register: 'ลงทะเบียนตอนนี้',
        Language: 'ไทย',
        Next: 'ดำเนินการต่อ',
        Submit: 'ส่ง',
        Back: 'กลับ',
        Acc: 'ตกลง',
        Pic: 'เลือกรูปภาพ',
        Close: 'ปิด',
        Addmore: 'เพิ่มเติม',
        toRegist: 'มาเป็นหุ้นส่วนกับเรา',
        schedule: 'เพิ่มกำหนดการใหม่',
        edit: 'แก้ไข',
        delete:'ลบ'
    },
    
    // text
    text: {
        welcome: 'สวัสดี ยินดีต้อนรับสู่ร้านค้าแม็กซิม',
        checkAgree: {
            agree: 'คุณเห็นด้วยกับ',
            and: 'และ',
            merchAgree: 'ข้อตกลงผู้ค้า',
            pvc: 'นโยบายความเป็นส่วนตัว'
        },
        
        landing_content : {
            title : {
                deliver: 'ราคาถูกและรวดเร็วในการจัดส่ง',
                price: 'ราคาเดียวกับร้านค้า'
            },
            subTitle: {
                deliver:'เราให้บริการจัดส่งราคาถูกและรวดเร็วเพื่อให้แน่ใจว่าลูกค้าจะได้รับประสบการณ์ที่มีคุณภาพดีในบริการราคาถูก',
                price:'เราต้องการมอบระบบนิเวศทางธุรกิจที่ยุติธรรมให้กับทุกคน โดยพันธมิตรผู้ค้าของเราไม่ต้องเสียคอมมิชชัน เพื่อให้แน่ใจว่าลูกค้าจะได้รับราคาเท่ากันกับซื้อโดยตรงกับร้านค้า'
            }
        },
        landingFooter : {
            app: 'สั่งซื้อกับแม็กซิม',
            contact: 'ติดต่อเรา'
        },
        Tutorial_content : [
            {
                title: 'เลือกบริการมาร์เก็ตเพลสบนแอป แม็กซิม ของคุณ',
                desc: [
                    'เปิดใช้แอพแม็กซิมของคุณ',
                    `เลือกบริการมาร์เก็ตเพลสและเลือก "Foods&Goods"`,
                    `วางตำแหน่งปลายทาง`,
                    `คลิก "What to buy" หรือปุ่ม สั่งซื้อ`
                ]
            },
            {
                title: 'เรียกดูและค้นหาผู้ขายที่คุณต้องการซื้อ',
                desc: [
                    `ใช้คุณลักษณะการค้นหาเพื่อค้นหาผู้ขายหรือสินค้าที่คุณต้องการซื้อ`,
                    `เลือก "Near me" เพื่อจัดเรียงตามระยะทางเริ่มต้นจากที่อยู่ใกล้ที่สุดไปยังตําแหน่งของคุณ`,
                    `หรือเลือก “By District” เพื่อค้นหาร้านค้าในเขตเฉพาะ`,
                ]
            },
            {
                title: 'ซื้อผลิตภัณฑ์ที่คุณต้องการและยืนยันการสั่งซื้อ',
                desc: [
                    `คลิก "Add" เพื่อใส่สินค้าที่คุณต้องการซื้อลงในรถเข็นของคุณ`,
                    'คลิก "View my cart" หลังจากเสร็จสิ้นการเลือกผลิตภัณฑ์',
                    `จากนั้น “Confirm your order”`,
                ]
            },
            {
                title: 'ตรวจสอบสถานะการสั่งซื้อของคุณและติดตามคําสั่งซื้อของคุณ',
                desc: [
                    `ดูสถานะคําสั่งซื้อและการตอบกลับจากผู้ขาย`,
                    `คลิก "Check Delivery Status" เพื่อดูความคืบหน้าในการจัดส่ง`
                ]
            },
            {
                title: 'คนขับรถของเราจะรับและส่งมอบสินค้าที่คุณซื้อ',
                desc: [
                    `คนขับรถของเราจะนำคำสั่งซื้อของคุณไปพร้อมกับบริการที่ราคาถูก ดี และรวดเร็ว`
                ]
            }
        ],
    },
    err: {
        notfilled: `แบบฟอร์มไม่สามารถเว้นว่างได้`,
        notemail: `ที่อยู่อีเมลไม่ถูกต้อง`,
        notpass: `รหัสผ่านไม่รัดกุม ขั้นต่ำ 7 ตัวอักษร และพยายามรวมตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก ตัวเลข และอักขระพิเศษ (!@#$%^&*_+=-)`,
        passnotsame: 'รหัสผ่านและการยืนยันรหัสผ่านต้องเหมือนกัน',
        noloc: 'เลือกตําแหน่งของคุณจากแผนที่ (คลิกแผนที่สองครั้งเพื่อรับตําแหน่งปัจจุบันของคุณหรือย้ายตัวชี้ไปยังตําแหน่งของคุณ)' ,
        nopic: 'เลือกรููปภาพเพื่ออัปโหลด',
        sent: 'ข้อผิดพลาดขณะส่งข้อมูลไปยังเซิร์ฟเวอร์',
        emailsent: 'อีมลได้รับการลงทะเบียนแล้ว',
        picFail: `ไม่สามารถอัพโหลดภาพได้ กรุณาใช้ภาพอื่น`,
        nodata: 'ไม่มีข้อมูล',
        get: 'เกิดข้อผิดพลาดขณะรับข้อมูล'
    },
    option : {
        yes: "ใช่",
        no: "ไม่"
    },
    day :{
        sunday: 'อาทิตย์',
        monday: 'วันจันทร์',
        tuesday: 'วันอังคาร',
        wednesday: 'วันพุธ',
        thursday: 'พฤหัสบดี',
        friday: 'วันศุกร์',
        saturday: 'วันเสาร์',
    }

}

export default Th