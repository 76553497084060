const My = {
    // title
    title : {
        agreement: 'PERJANJIAN PENIAGA',
        regis: 'PENDAFTARAN PENIAGA',
        regok: 'PENDAFTARAN BERJAYA',
        addok: 'BERJAYA TAMBAH PRODUK',
        editok: 'BERJAYA EDIT PRODUK',
        product_add: 'TAMBAH PRODUK',
        product_edit: 'EDIT PRODUK',
        landing: `Beli Apa Yang Anda Mahu, Kami Akan Hantar`,
        landingContent: 'Kenapa Pilih Kami?',
        openingHour: 'WAKTU PEMBUKAAN',
        modalAdd:'Tambah',
        modalEdit:'Sunting'
    },
    
    // subtitle
    subTitle : {
        regis: 'MULAKAN MENJUAL PRODUK ANDA MELALUI MAXIM ORDER SERVICE',
        agreement: 'SILA BACA PERJANJIAN SEBELUM MENERUSKAN', 
        detail: 'BUTIRAN PENIAGA',
        location: 'BUTIRAN LOKASI',
        pic: 'GAMBAR PENIAGA',
        regok:'Terima kasih kerana menyertai Maxim Merchant dan sila tunggu pengesahan daripada kami. Anda boleh mengakses aplikasi melalui pautan di bawah.',
        addok:'Produk ditambah. Anda boleh menambah lagi atau anda boleh menutup halaman ini',
        editok:'Produk sudah disunting. Anda boleh menutup halaman ini',
        landing:'Harga produk yang berpatutan dengan penghantaran yang berpatutan',
    },
    
    // Form
    form : {
        name: 'Nama kedai',
        desc: 'Penerangan',
        email: 'Emel',
        password: 'Kata laluan',
        passconf: 'Pengesahan kata laluan',
        phonenumber: 'Nomor telefon',
        address: 'Alamat',
        city: 'Bandar',
        district: 'Daerah',
        upload: 'Muat Naik Gambar Kedai',
        product_name: 'Nama produk',
        product_category: 'Kategori',
        product_price: 'Harga',
        product_disc: 'Diskaun',
        product_discamnt: 'Jumlah diskaun',
        product_rec: 'Disyorkan',
        day: 'Hari',
        timeOpen: 'Masa Dibuka',
        timeClose: 'Masa Tutup',
    },
    
    // note
    note : {
        map: 'Besarkan peta untuk lokasi yang lebih tepat dan ketik/klik dua kali untuk lokasi semasa',
    },
    
    // button
    btn: {
        Register: 'Daftar sekarang',
        Language: 'Melayu',
        Next: 'Teruskan',
        Submit: 'Hantar',
        Back: 'Sebelumnya',
        Acc: 'Setuju',
        Pic: 'Pilih Gambar',
        Close: 'Tutup',
        Addmore: 'Tambah Lagi',
        toRegist: 'Jadi Rakan Kongsi Kami',
        schedule: 'Tambah Jadual Baharu',
        edit: 'Sunting',
        delete:'Padam'
    },
    
    // text
    text: {
        welcome: 'Hello Selamat datang ke Maxim Merchant',
        checkAgree: {
            agree: 'Anda bersetuju dengan',
            and: 'dan',
            merchAgree: 'Perjanjian Peniaga',
            pvc: 'Dasar Privasi'
        },
        
        landing_content : {
            title : {
                deliver: 'Penghantaran Murah dan Cepat',
                price: 'Harga Sama seperti di kedai'
            },
            subTitle: {
                deliver:'Kami menyediakan perkhidmatan penghantaran yang murah dan pantas untuk memastikan pelanggan boleh mendapatkan pengalaman berkualiti dengan perkhidmatan kos rendah.',
                price:'Kami ingin menyediakan ekosistem perniagaan yang adil kepada semua, kami memberi rakan kongsi peniaga kami tanpa komisen untuk memastikan pelanggan boleh mendapatkan harga yang sama seperti semasa membeli terus ke kedai'
            }
        },
        landingFooter : {
            app: 'Pesan dengan Maxim',
            contact: 'Hubungi Kami'
        },
        Tutorial_content : [
            {
                title: 'Pilih perkhidmatan Food pada Maxim Apps anda',
                desc: [
                    'Buka Aplikasi Maxim anda',
                    `Pilih perkhidmatan Food dan pilih "Foods&Goods"`,
                    `Letakkan lokasi destinasi`,
                    `Klik "Apa yang hendak dibeli" atau butang ORDER`
                ]
            },
            {
                title: 'Semak imbas dan cari peniaga yang ingin anda beli',
                desc: [
                    `Gunakan ciri carian untuk mencari peniaga atau produk yang ingin anda beli`,
                    `Pilih "Dekat saya" untuk mengisih mengikut jarak bermula dari yang terdekat dengan lokasi anda.`,
                    `Atau pilih "Mengikut Daerah" untuk mencari pedagang di daerah tertentu.`,
                ]
            },
            {
                title: 'Beli produk yang anda inginkan dan sahkan pesanan',
                desc: [
                    `Klik "Tambah" untuk meletakkan produk yang anda ingin beli ke dalam troli anda`,
                    'Klik "Lihat troli saya" selepas selesai memilih produk.',
                    `Kemudian "Sahkan pesanan anda"`,
                ]
            },
            {
                title: 'Semak status pesanan anda & jejak pesanan anda',
                desc: [
                    `Lihat status pesanan anda dan maklum balas daripada peniaga`,
                    `Klik "Semak Status Penghantaran" untuk melihat kemajuan penghantaran`
                ]
            },
            {
                title: 'Pemandu kami akan memilih & menghantar barang yang anda beli.',
                desc: [
                    `Pemandu kami akan membawa pesanan anda dengan perkhidmatan yang murah, baik dan pantas.`
                ]
            }
        ],
    },
    err: {
        notfilled: `Borang tidak boleh kosong`,
        notemail: `Alamat e-mel tidak sah`,
        notpass: `Kata laluan tidak kuat, minimum 7 aksara dan cuba gabungkan huruf besar, huruf kecil, nombor dan aksara khas (!@#$%^&*_+=-)`,
        passnotsame: 'Kata Laluan dan Pengesahan Kata Laluan mestilah sama',
        noloc: 'Pilih lokasi anda daripada peta (klik peta dua kali untuk mendapatkan lokasi semasa anda atau alihkan penuding ke lokasi anda)' ,
        nopic: 'Pilih foto untuk dimuat naik',
        sent: 'Ralat Semasa Menghantar Data Ke Pelayan',
        emailsent: 'E-mel sudah Berdaftar',
        picFail: `Tidak dapat memuat naik gambar, sila gunakan foto lain`,
        nodata: 'Tiada data',
        get: 'Ralat Semasa Mendapatkan Data'
    },
    option : {
        yes: "Ya",
        no: "Tidak"
    },
    day :{
        sunday: 'Ahad',
        monday: 'Isnin',
        tuesday: 'Selasa',
        wednesday: 'Rabu',
        thursday: 'Khamis',
        friday: 'Jumaat',
        saturday: 'Sabtu',
    }

}

export default My