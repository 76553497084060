import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { t } from '../../../misc/utils';

// Component
import { TextField, Button, Autocomplete } from '@mui/material';
import { getCity, getDistrict, LocationMerchantDetail, PositionMerchant, backPage } from '../../../application/action'
import { MapContainer, Marker,  TileLayer, useMapEvents } from "react-leaflet";
import { Language } from '@mui/icons-material'
import { Modal } from "react-bootstrap"
import {LanguageComponent} from '../../'
import {useMediaQuery} from 'react-responsive'
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Assets
import BG from '../../../assets/image/bg-d_1.svg'
import SPLASH from '../../../assets/image/splash_1.svg'

const defaultPosition = {
    lat: -6.177001581818966,
    lng: 106.82716516100207
}

function LocationMarker() {
    const dispatch = useDispatch()
    const [position, setPosition] = React.useState(null)
    const markerRef = React.useRef(null)
    const { lat, lon } = useSelector((state) => {
        return {
            lat: state.Register.lat,
            lon: state.Register.lon
        }
    })
    const defaultMarker = new L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [13, 0]
    });
    const map = useMapEvents({
        dblclick() {
            map.locate()
        },
        locationfound(e) {
            setPosition(e.latlng)
            dispatch(PositionMerchant(e.latlng))
            map.flyTo(e.latlng, map.getZoom())
        },
    })
    React.useEffect(() => {
        map.flyTo({lat:lat, lng:lon}, map.getZoom())
    },[lat, lon])
    const eventHandlers = React.useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    dispatch(PositionMerchant(marker.getLatLng()))
                }
            },
        }),
        [],
    )

    return position === null ? (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={{lat:lat, lon:lon}}
            ref={markerRef}
            icon={defaultMarker}
        >
        </Marker>) : (
        <Marker
            draggable={true}
            eventHandlers={eventHandlers}
            position={{lat:lat, lon:lon}}
            ref={markerRef}
            icon={defaultMarker}
        >
        </Marker>
    )
}

const RegisterMerchantLocation = () => {
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
      })
    const translate = localStorage.getItem('language')

    const dispatch = useDispatch()
    const [selectedCity, setSelectedCity] = React.useState(null)
    const [selectedDistrict, setSelectedDistrict] = React.useState(null)
    const [ok, setOk] = React.useState(false)
    const [err, setError] = React.useState(null)


    React.useEffect(() => {
        dispatch(getCity())
        dispatch(getDistrict(selectedCity))
    }, [selectedCity])
    const { cities, district, lat, lon, Address } = useSelector((state) => {
        return {
            cities: state.Register.data_cities,
            district: state.Register.data_district,
            lat: state.Register.lat,
            lon: state.Register.lon,
            Address: state.Register.address,
        }
    })

    const [address, setAddress] = React.useState(Address)

    const nextBtn = () => {
        if (address.length === 0 || selectedCity === null || selectedDistrict === null) setError(t.err.notfilled)
        else if (!lat && !lon) setError(t.err.noloc)
        else {
            let data = {
                address: address,
                cities_id: selectedCity,
                district_id: selectedDistrict
            }

            dispatch(LocationMerchantDetail(data))
            // setOk(true)
        }
    }
    // if (ok) return (<Redirect to='/registerpic' />)
    return (
        <div>

            <div style={{
                float: 'left', width: mobile ? '100%' : '50%',
                margin: 'auto',
                padding: 30
            }}>
                <div style={{ color: '#000000', borderColor: '#000000', borderRadius: 20, fontSize: 20, float: 'right', padding: 8, width: 200, display: 'flex', flexDirection: 'row' }}>
                    <Language style={{ margin: 10 }} />
                    <LanguageComponent style={{ width: 90 }} value={translate}/>
                </div>
                <div style={{ marginTop: 100 }}>
                    <h1 style={{ fontSize: 18, fontWeight: 500 }}>{t.title.regis}</h1>
                    <h1 style={{ fontSize: 16, fontWeight: 300 }}>{t.subTitle.location}</h1>
                    <div className='Form'>
                        <TextField id="outlined-basic" defaultValue={address} autoComplete='off' label={t.form.address} variant="outlined" color='warning' style={styles.formField} onChange={(e) => setAddress(e.target.value)} />
                        <Autocomplete
                            disablePortal
                            options={cities.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                            getOptionLabel={(options) => options ? options.name : ''}
                            onChange={(e, newValue) => setSelectedCity(newValue ? newValue.id : null)}
                            renderInput={(params) => <TextField {...params} id="outlined-basic" color='warning' style={styles.formField} label={t.form.city} />}
                        />
                        <Autocomplete
                            disabled={selectedCity === null}
                            disablePortal
                            options={district.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))}
                            getOptionLabel={(options) => options ? options.name : ''}
                            onChange={(e, newValue) => {
                                setSelectedDistrict(newValue ? newValue.id : null)
                                dispatch(PositionMerchant({
                                    lat: newValue.lat,
                                    lng: newValue.lon
                                    }))
                            }}
                            renderInput={(params) => <TextField {...params} id="outlined-basic" color='warning' style={styles.formField} label={t.form.district} />}
                        />
                        <div style={{ display: 'flex', flexDirection: mobile ? 'column' : 'row' }}>
                            <TextField id="outlined-basic" disabled label="Latitude" variant="outlined" value={lat ? lat : ''} color='warning' style={styles.formField} inputProps={{ readOnly: true }} />
                            <TextField id="outlined-basic" disabled label="Longitude" variant="outlined" value={lon ? lon : ''} color='warning' style={styles.formField} inputProps={{ readOnly: true }} />
                        </div>
                    </div>
                    <div id="map" style={{ margin: 20, marginLeft: 30 }}>
                        <div className="map-container">
                            <MapContainer
                                style={{ width: "100%", height: "400px" }}
                                center={{lat:lat, lon: lon}}
                                zoom={20}
                                scrollWheelZoom={true}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LocationMarker />
                            </MapContainer>
                            <p>*{t.note.map}</p>
                        </div>
                    </div>
                    <div style={styles.button_container}>
                        <Button variant='outlined' style={{ borderColor: '#FF6000', color: '#FF6000', fontSize: 16, width: 120, fontWeight: 'bold', height: 50, borderRadius: 30 }} onClick={() => dispatch(backPage())} >{t.btn.Back}</Button>
                        <Button variant="contained" style={{ marginLeft: 10, padding: 10, backgroundColor: '#FF6000', color: 'white', fontWeight: 'bold', width: 120, height: 50, fontSize: 16, borderRadius: 30 }} onClick={nextBtn}>{t.btn.Next}</Button>
                    </div>
                    {/* <Button variant="contained" style={{ width: '100%', backgroundColor: 'orange' }} onClick={nextBtn}>Next</Button> */}
                </div>

            </div>
            {mobile ? <></> : <div style={{
                float: 'right', width: '50%', height: '100vh',
                backgroundImage: "url(" + BG + ")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'fixed',
                right: 0
            }}>
                <img src={SPLASH} style={{ right: 0, bottom: 0, position: "fixed" }} />
            </div>}
            <Modal show={err} onHide={() => setError(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error !</Modal.Title>
                </Modal.Header>
                <Modal.Body>{err}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setError(null)}>
                        {t.btn.Close}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RegisterMerchantLocation

const styles = {
    container_register: {
        margin: 'auto',
        width: '80%',
        padding: 10
    },
    formField: {
        marginTop: 10,
        marginLeft: 10,
        marginBottom: 10,
        width: '100%',
    },
    button_container: {
        float: 'right'
    }
}