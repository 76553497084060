import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PasswordMeter } from 'password-meter';
import { DataMerchantDetail } from '../../../application/action'
import { t } from '../../../misc/utils'

// Component
import { Button, IconButton, TextField } from '@mui/material';
import { Language, Visibility, VisibilityOff } from '@mui/icons-material'
import { Modal } from "react-bootstrap"
import PhoneInput from 'react-phone-input-2'
import {LanguageComponent} from '../../'
import {useMediaQuery} from 'react-responsive'

// Assets
import BG from '../../../assets/image/bg-d_1.svg'
import SPLASH from '../../../assets/image/splash_1.svg'

function RegisterMerchantDetail() {
  const mobile = useMediaQuery({
    query: '(max-width:990px)'
  })
  const {
    name,
    email,
    password,
    password_confirmation,
    desc,
    phone,
    ref
  } = useSelector((state) => {
    return {
      name: state.Register.name,
      email: state.Register.email,
      password: state.Register.password,
      password_confirmation: state.Register.password_confirmation,
      desc: state.Register.desc,
      phone: state.Register.phone,
      ref: state.Register.ref
    }
  })
  const translate = localStorage.getItem('language')
  const dispatch = useDispatch()
  const [storeName, setStoreName] = React.useState(name)
  const [Desc, setDesc] = React.useState(desc)
  const [Email, setEmail] = React.useState(email)
  const [Pass, setPass] = React.useState(password)
  const [RePass, setRePass] = React.useState(password_confirmation)
  const [Wa, setWa] = React.useState(phone)
  const [Ref, setRef] = React.useState(ref)
  const [vis1, setVis1] = React.useState(false)
  const [vis2, setVis2] = React.useState(false)
  const [err, setError] = React.useState(null)

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let test = re.test(String(email).toLowerCase());
    if (!test) return false
    else return true
  }
  function validatePassWord(pass) {
    let passTest = new PasswordMeter().getResult(pass)
    if (passTest.status === 'perfect' || passTest.status === 'strong') return true
    else return false
  }
  const nextBtn = () => {
    if (storeName.length === 0 || Email.length === 0 || Pass.length === 0 || RePass.length === 0 || Wa.length === 0 || Desc.length === 0) setError(t.err.notfilled)
    else {
      if (!validatePassWord(Pass)) setError(t.err.notpass)
      else if (!validateEmail(Email)) setError(t.err.notemail)
      else if (Pass !== RePass) setError(t.err.passnotsame)
      else {
        let data = {
          name: storeName,
          email: Email,
          password: Pass,
          password_confirmation: RePass,
          desc: Desc,
          phone: Wa,
          ref:Ref
        }
        dispatch(DataMerchantDetail(data))
      }
    }
  }
  return (
    <div>
      <div style={{
        float: 'left', width: mobile ? '100%' : '50%',
        margin: 'auto',
        padding: 30
      }}>
        <div style={{ color: '#000000', borderColor: '#000000', borderRadius: 20, fontSize: 20, float: 'right', padding: 8, width: 200, display: 'flex', flexDirection: 'row' }}>
          <Language style={{ margin: 10 }} />
          <LanguageComponent style={{ width: 90 }} value={translate}/>
        </div>
        <div style={{ marginTop: 100 }}>
          <h1 style={{ fontSize: 18, fontWeight: 500 }}>{t.title.regis}</h1>
          <h1 style={{ fontSize: 16, fontWeight: 300 }}>{t.subTitle.detail}</h1>
          <div className='Form'>
            <TextField id="outlined-basic" defaultValue={storeName} autoComplete='off' label={t.form.name} variant="outlined" color='warning' style={styles.formField} onChange={(e) => setStoreName(e.target.value)} />
            <TextField id="outlined-basic" defaultValue={Desc} autoComplete='off' label={t.form.desc} variant="outlined" color='warning' style={styles.formField} onChange={(e) => setDesc(e.target.value)} />
            <TextField id="outlined-basic" defaultValue={Email} autoComplete='off' type='email' label={t.form.email} variant="outlined" color='warning' style={styles.formField} onChange={(e) => setEmail(e.target.value)} />
            <TextField id="outlined-basic" autoComplete='off' defaultValue={Pass}
              InputProps={{
                endAdornment: vis1 ?
                  <IconButton onClick={() => setVis1(false)}>
                    <VisibilityOff />
                  </IconButton>
                  :
                  <IconButton onClick={() => setVis1(true)}>
                    <Visibility />
                  </IconButton>
              }} label={t.form.password} type={vis1 ? 'text' : 'password'} variant="outlined" color='warning' style={styles.formField} onChange={(e) => setPass(e.target.value)} />
            <TextField id="outlined-basic" autoComplete='off' defaultValue={RePass}
              InputProps={{
                endAdornment: vis2 ?
                  <IconButton onClick={() => setVis2(false)}>
                    <VisibilityOff />
                  </IconButton>
                  :
                  <IconButton onClick={() => setVis2(true)}>
                    <Visibility />
                  </IconButton>
              }} type={vis2 ? 'text' : 'password'} label={t.form.passconf} variant="outlined" color='warning' style={styles.formField} onChange={(e) => setRePass(e.target.value)} />
            <PhoneInput placeholder={t.form.phonenumber}  autoFormat={false} onChange={(e) => setWa(e)} country='id' containerStyle={{ marginBottom:10}} inputStyle={{ width:'100%'}} countryCodeEditable={false}/>
            <TextField defaultValue={Ref} id="outlined-basic"  autoComplete='off' label='Referral' variant="outlined" color='warning' style={{marginTop: 10, marginBottom: 10, width: '100%',borderRadius: 60}} onChange={(e)=> setRef(e.target.value)}  />
          </div>
          <div style={styles.button_container}>
            <Button variant='outlined' style={{ borderColor: '#FF6000', color: '#FF6000', fontSize: 16, width: 120, fontWeight: 'bold', height: 50, borderRadius: 30 }} href='/register'>{t.btn.Back}</Button>
            <Button variant="contained" style={{ marginLeft: 10, padding: 10, backgroundColor: '#FF6000', color: 'white', fontWeight: 'bold', width: 120, height: 50, fontSize: 16, borderRadius: 30 }} onClick={nextBtn}>{t.btn.Next}</Button>
          </div>
        </div>
      </div>
      {mobile ? <></> : <div style={{
        float: 'right', width: '50%', height: '100vh',
        backgroundImage: "url(" + BG + ")",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        right: 0
      }}>
        <img src={SPLASH} style={{ right: 0, bottom: 0, position: "fixed" }} />
      </div>}
      <Modal show={err} onHide={() => setError(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Error !</Modal.Title>
        </Modal.Header>
        <Modal.Body>{err}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setError(null)}>
            {t.btn.Close}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );
}

export default RegisterMerchantDetail;

const styles = {
  formField: {
    marginTop: 10,
    // marginLeft: 10,
    marginBottom: 10,
    width: '100%',
    borderRadius: 60
  },
  button_container: {
    float: 'right',
  }
}