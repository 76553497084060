import { t, t_pvc} from '../../misc/utils'

//css
import './style.css'

// Component
import { useMediaQuery } from 'react-responsive'
import { Button } from '@mui/material'
import { Language } from '@mui/icons-material'
import { LanguageComponent } from '..'
// import constant from '../misc/constant'

// Assets
import BG from '../../assets/image/bg-d_1.svg'
import SPLASH from '../../assets/image/splash_1.svg'
const PrivacyPolicy = () => {
    const SearchParams = new URLSearchParams(window.location.search)
    const params = SearchParams.get('from')
    // console.log(params.get('from'))
    const translate = localStorage.getItem('language')
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
    })

    return (
        <div>
            <div className="box-left">
                <div className="top-content">
                    <Language className="icon-world" />
                    <LanguageComponent className="translate" value={translate} />
                </div>
                <div className="middle-content">
                    <h1>{t_pvc.title.privacy}</h1>
                    <h2>{t_pvc.subTitle.privacy1}</h2>
                    <h2>{t_pvc.subTitle.privacy2}</h2>
                    <div className='part1'>
                        <h6>{t_pvc.pvc.part1}</h6>
                        <h3>{t_pvc.pvc_part1.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part1.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part2'>
                        <h6>{t_pvc.pvc.part2}</h6>
                        <h3>{t_pvc.pvc_part2.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part2.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                            {
                                                t_pvc.pvc_part2.bag2.privacy_sub_content[index + 1].map((itm, idx) => {
                                                    return (
                                                        <h3>{itm}</h3>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part3'>
                        <h6>{t_pvc.pvc.part3}</h6>
                        <h3>{t_pvc.pvc_part3.bag1}</h3>
                        <ol className='column1'>
                            <li>{t_pvc.pvc_part3.bag2.privacy_content[0]}</li>
                            {
                                t_pvc.pvc_part3.bag2.privacy_sub_content1.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <h3>{item}</h3>
                                            {
                                                t_pvc.pvc_part3.bag2.privacy_sub_sub_content[index+1].map((itm, idx) => {
                                                    return (
                                                        <h3 className='column1'>{itm}</h3>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            <li>{t_pvc.pvc_part3.bag2.privacy_content[1]}</li>
                            {t_pvc.pvc_part3.bag2.privacy_sub_content2.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <h3>{item}</h3>
                                        {
                                            t_pvc.pvc_part3.bag2.privacy_sub_sub_content[index+1].map((itm, idx) => {
                                                return (
                                                    <div className='column1'>
                                                        <h3>{itm}</h3>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })}
                            {t_pvc.pvc_part3.bag3.privacy_content.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <li>{item}</li>
                                        {
                                            t_pvc.pvc_part3.bag3.privacy_sub_content[index+1].map((itm, idx) => {
                                                return (
                                                    <div className='column1'>
                                                        <h3>{itm}</h3>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })}
                        </ol>
                    </div>
                    <div className='part4'>
                        <h6>{t_pvc.pvc.part4}</h6>
                        <h3>{t_pvc.pvc_part4.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part4.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                            {
                                                t_pvc.pvc_part4.bag2.privacy_sub_content[index + 1].map((itm, idx) => {
                                                    return (
                                                        <h3>{itm}</h3>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part5'>
                        <h6>{t_pvc.pvc.part5}</h6>
                        <h3>{t_pvc.pvc_part5.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part5.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                            {
                                                t_pvc.pvc_part5.bag2.privacy_sub_content[index + 1].map((itm, idx) => {
                                                    return (
                                                        <h3>{itm}</h3>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part6'>
                        <h6>{t_pvc.pvc.part6}</h6>
                        <h3>{t_pvc.pvc_part6.bag1}</h3>
                        {
                            t_pvc.pvc_part6.bag2.privacy_content.map((item, index) => {
                                return (
                                    <h3>{item}</h3>
                                )
                            })
                        }
                    </div>
                    <div className='part7'>
                        <h6>{t_pvc.pvc.part7}</h6>
                        <h3>{t_pvc.pvc_part7.bag1}</h3>
                    </div>
                    <div className='part8'>
                        <h6>{t_pvc.pvc.part8}</h6>
                        <h3>{t_pvc.pvc_part8.bag1}</h3>
                    </div>
                    <div className='part9'>
                        <h6>{t_pvc.pvc.part9}</h6>
                        <h3>{t_pvc.pvc_part9.bag1}</h3>
                        <ol className='column1'>
                            <li>{t_pvc.pvc_part9.bag2.privacy_content[0]}</li>
                            <li>{t_pvc.pvc_part9.bag2.privacy_content[1]}</li>
                            {
                                t_pvc.pvc_part9.bag2.privacy_sub_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <h3>{item}</h3>
                                            {
                                                t_pvc.pvc_part9.bag2.privacy_sub_sub_content[index + 1].map((itm, idx) => {
                                                    return (
                                                        <h3 className='column1'>{itm}</h3>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                            <li>{t_pvc.pvc_part9.bag2.privacy_content[2]}</li>
                        </ol>
                    </div>
                    <div className='part10'>
                        <h6>{t_pvc.pvc.part10}</h6>
                        <h3>{t_pvc.pvc_part10.bag1}</h3>
                    </div>
                    <div className='part11'>
                        <h6>{t_pvc.pvc.part11}</h6>
                        <h3>{t_pvc.pvc_part11.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part11.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part12'>
                        <h6>{t_pvc.pvc.part12}</h6>
                        <h3>{t_pvc.pvc_part12.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part12.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part13'>
                        <h6>{t_pvc.pvc.part13}</h6>
                        <h3>{t_pvc.pvc_part13.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part13.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part14'>
                        <h6>{t_pvc.pvc.part14}</h6>
                        <h3>{t_pvc.pvc_part14.bag1}</h3>
                        <ol className='column1'>
                            {
                                t_pvc.pvc_part14.bag2.privacy_content.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <li>{item}</li>
                                            {
                                                t_pvc.pvc_part14.bag2.privacy_sub_content[index + 1].map((itm, idx) => {
                                                    return (
                                                        <h3>{itm}</h3>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    <div className='part15'>
                        <h6>{t_pvc.pvc.part15}</h6>
                        <h3>{t_pvc.pvc_part15.bag1}</h3>
                    </div>
                    <div className='part16'>
                        <h6>{t_pvc.pvc.part16}</h6>
                        <h3>{t_pvc.pvc_part16.bag1}</h3>
                    </div>
                    { params === 'agreement' ?
                        <div className="middle-content-btn">
                            <Button variant='outlined' className="btn-back" href='/merchant-agreement?from=register' >{t.btn.Back}</Button>
                            <Button variant='contained' className="btn-go" href='/register/reg'>{t.btn.Acc}</Button>
                        </div>
                        :
                      params === 'register' ?
                        <div className="middle-content-btn">
                            <Button variant='outlined' className="btn-back" href='/register' >{t.btn.Back}</Button>
                            <Button variant='contained' className="btn-go" href='/merchant-agreement?from=pvc'>{t.btn.Acc}</Button>
                        </div> 
                        :
                        <></>
                    }
                </div>
            </div>
            {mobile ? <></> :
                <div className="bottom-content" style={{ backgroundImage: "url(" + BG + ")", backgroundSize: 'cover' }}>
                    <img src={SPLASH} />
                </div>}
        </div>
    )
}

export default PrivacyPolicy