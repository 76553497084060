import React from 'react'
import ReactGa from 'react-ga'
import { t } from '../../../misc/utils'

// Component
import {useMediaQuery} from 'react-responsive'
import { LanguageComponent } from '../../'

// Assets
import BG from '../../../assets/image/bg-d_1.svg'
import SPLASH from '../../../assets/image/splash_1.svg'
import { Button } from '@mui/material'
import { Language } from '@mui/icons-material'

const RegisterDone = () => {

    React.useEffect(() => {
        ReactGa.event({
            category: 'User',
            action: 'Created an Account'
          });
    })

    const translate = localStorage.getItem('language')
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
      })

    return (
        <div>
            <div style={{
                float: 'left', width: mobile ? '100%' : '50%',
                margin: 'auto',
                padding: 30
            }}>
                <div style={{ color: '#000000', borderColor: '#000000', borderRadius: 20, fontSize: 20, float: 'right', padding: 8, width: 200, display: 'flex', flexDirection: 'row' }}>
                    <Language style={{ margin: 10 }} />
                    <LanguageComponent style={{ width: 90 }} value={translate}/>
                </div>
                <div style={{ marginTop: 100, textAlign: 'center', }}>
                    <h1 style={{ fontSize: 25, fontWeight: 500, maxWidth: 397, margin: 'auto' }} >{t.title.regok}</h1>
                    <h1 style={{ fontSize: 22, fontWeight: 400, marginTop: 50 }} >{t.subTitle.regok}</h1>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Button href='https://maxim-merchant.onelink.me/YyIq?pid=test&c=test&af_click_lookback=7d' style={{ fontWeight: 500, width: 160, fontSize: 16, margin: 'auto', borderRadius: 30, height:60, marginTop: 30, marginBottom: 70, backgroundColor: '#D02C48', textTransform:'none' }} variant='contained'>MaximMerchant</Button>
                    </div>
                </div>

            </div>
            {mobile ? <></> : <div style={{
                float: 'right', width: '50%', height: '100vh',
                backgroundImage: "url(" + BG + ")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'fixed',
                right: 0
            }}>
                <img src={SPLASH} style={{ right: 0, bottom: 0, position: "fixed" }} />
            </div>}
        </div>
    )
}

export default RegisterDone