const privacy = {
    // title
    title: {
        privacy: 'Privacy Policy of MAXIM MERCHANT',
    },

    // subtitle
    subTitle: {
        privacy1: 'This Privacy Policy describes how we, THE SERVICE “MAXIM” (collectively hereinafter as “THE SERVICE”, “MAXIM” “us”, “our” or “we”) collect, use, process, disclose and protect your Personal and/or Business Data collected through the use of the Application and Websites of Maxim Merchant (respectively hereinafter as «App» and «Websites»), and our products or features in the App. This Privacy Policy applies to every User of our Websites or App which means including the Merchant Partners (collectively hereinafter referred to as “User”, “you”, “your” or “yours”), Customers, and Drivers.',
        privacy2: 'This Policy is an inalienable part of the Merchant Agreement which is accessible on our Website or App. When using the service through the Website or App, the User agrees to this Policy, including the processing of User’s Personal and/or Business Data by us in the instances when applicable laws require such consent.',
    },

    // note
    note: {
        map: 'Enlarge map for more accurate location, and tap/click twice for current location',
    },

    // button
    btn: {
        Back: 'Back',
        Acc: 'Agree',
    },

    pvc: {
        part1: "I. GENERAL PROVISIONS",
        part2: "II. COLLECTION OF Personal and/or Business Data",
        part3: "III. THE Personal and/or Business Data USAGE",
        part4: "IV. Personal and/or Business Data DISCLOSUR",
        part5: "V. Personal and/or Business Data PROTECTION",
        part6: "VI. SUPPLEMENTING AND ELIMINATION OF Personal and/or Business Data",
        part7: "VII. GEOGRAPHICAL LOCATION INFORMATION",
        part8: "VIII. JURISDICTION AND CROSS BORDER TRANSFER",
        part9: "IX. COOKIES",
        part10: "X. INFORMATION ON USER’S DEVICE",
        part11: "XI. DATA ABOUT TELECOMMUNICATION OPERATOR",
        part12: "XII. REQUEST RECORDS",
        part13: "XIII. THE ACKNOWLEDGMENT, THE CONSENT AND ITS WITHDRAWA",
        part14: "XIV. THE USER RIGHTS",
        part15: "XV. THE CHANGE OR UPDATES OF THIS POLICY",
        part16: "XVI. CONTACTS",
    },

    pvc_part1: {
        bag1: 'For the purpose of this Privacy Policy, the below listed terms shall be interpreted as follows:',
        bag2: {
            privacy_content: [
                '«Personal and/or Business Data»: Any information directly and/or indirectly related to you as a User of our Website or Apps, including but not limited to Merchant name, phone number (active Whatsapp account), bank and credit card details (if applicable), email address, description and category of Merchant, geographical location, your image, items and their prices, and descriptions and images of items.',
                '«Merchant Partner»:  A person or an entity, who has expressed his/her/its submission to the agreement and has received the right to use the Merchant App, and therefore shall enjoy the rights given and fulfill the responsibilities in the context of providing food and beverages and/or groceries to fulfill the needs of the Client through their orders from the Mobile App. ',
                '«Driver Partner»:  A person who independently and on his/her own volition agrees to provide transportation services by motorbike.',
                '«Customer»:  A person who has requested via our Customer Apps or website, and has provided his/her Personal Data for this purpose.',
                '«The Services»: The information provided to the Customer through our Apps or Website, in order for the Customer’s Request to be accepted, processed and transmitted to the Partners (both Driver and Merchant Partners) and the execution of such Request be communicated back to the Customer. The subject and methods of providing services will be identified according to the customers’ offers on our website.',
                '«Processing of Personal and/or Business Data»: any activity or activities done on Personal and/or Business Data, with or without automation, including collection, recording, systematizing, saving, storing, organizing (updating, correcting), abstracting, using, communicating (distributing, providing, accessing), making un-private, blocking, deleting and eliminating Personal and/or Business Data.',
                '«Blocking Personal and/or Business Data»: temporary halt in processing of Personal and/or Business Data (excluding instances when processing is necessary to identify Personal and/or Business Data)',
                '«Eliminating Personal and/or Business Data»: activities that result in making the existing Personal and/or Business Data unrecoverable and/or result in eliminating the Personal and/or Business Data Storage.'
            ],

        }
    },

    pvc_part2: {
        bag1: 'The User’s Personal and/or Business Data is stored in electronic storage and is processed by automated systems for Processing of Personal and/or Business Data.',
        bag2: {
            privacy_content: [
                'We collect User’s Personal and/or Business Data when the User voluntarily provide it to us. For example, the User may provide his/her Personal and/or Business Data to us when the User:',
                'Personal and/or Business Data may be collected through the normal operation of the Services, such data includes, for example:',
                'When we collect Personal and/or Business Data from other sources, we make sure that that data is transferred to us in accordance with applicable laws. The aforementioned sources include:',
                'When the User register and create an account in our website, we collect and process the following Personal and/or Business Data of Partner:',
                'In particular, we may collect and process the device data (GPS location, IMEI number) of the User for analysis and administrative purposes.',
                'In the following instances the User’s Personal and/or Business Data will be eliminated by us:',
                'The elimination of the Personal and/or Business Data will be done in such a way as to be unrecoverable.',
                'In the event that any Personal and/or Business Data of a minor (i.e. individuals under the age of 18) is disclosed to us, you hereby signify your consent to the processing of the minor’s Personal and/or Business Data as a parent or legal guardian of the minor (or your agreement to procure the necessary consent from the minor’s parent or legal guardian) and accept and agree to be bound by this Policy and take responsibility for his or her actions. In the event that you are a minor and intend to provide your Personal and/or Business Data to us, you hereby confirm and acknowledge that you have obtained your parent or legal guardian’s agreement to be bound by this Policy.',
            ],
            privacy_sub_content: {
                1: [
                    '(1) fills in user profile or registration forms;',
                    '(2) provides information to assess User’s eligibility to provide Services as a Merchant Partner;',
                    '(3) interacts with our social media pages;',
                    '(4) participates in contests or events organized by us; and',
                    '(5) provides demographic information in surveys.',
                ],
                2: [
                    '(1) location;',
                    '(2) in-Apps transaction information (including the payment method);',
                    '(3) chat messages when using our in-Apps communication features;',
                    '(4) feedback, ratings and compliments;',
                    '(5) the user’s device information;',
                    '(6) every Personal and/or Business Data which can be captured through User interaction with us.', ,
                ],
                3: [
                    '(1) Referral programs;',
                    '(2) Our business partners;',
                    '(3) Publicly available data;',
                ],
                4: [
                    '(1) Merchant name',
                    '(2) The registered phone number which also bound to an active Whatsapp account',
                    '(3) Email',
                    '(4) Photo of the User’s shop',
                    '(5) Full information on the User’s shop location',
                    '(6) Merchant description',
                ],
                5: [],
                6: [
                    '(1) After three years after completion of providing services;',
                    '(2) In case the User retracts his/her consent for Processing his/her Personal and/or Business Data.',
                ],
                7: [],
                8: [],
            }
        }

    },

    pvc_part3: {

        bag1: 'The main purpose of this Privacy Policy between the User and us is for Processing of Personal and/or Business Data of the User, which is listed below.',
        bag2: {
            privacy_content: [
                'In general, the User’s Personal and/or Business Data will be used to provide, personalize, maintain and improve our Services. This includes using the Personal and/or Business Data to:',
                'In Specific, we can use the User’s Personal and/or Business Data for the following purposes:',
            ],
            privacy_sub_content1: [
                '(1) provide you with the orders;',
                '(2) engage the Driver Partner in providing the Services;',
                '(3) create, administer and update User’s account;',
                '(4) verify the User’s identity;',
                '(5) process payments;',
                '(6) offer, obtain, provide or facilitate insurance for the order (if applicable);',
                '(7) track the progress of the order;',
                '(8) enable features that personalize your App, such as lists of favorite items according to the Customers',
                '(9) perform internal operations necessary to provide our Services, including troubleshooting software bugs and operational problems, conducting data analysis, testing and research, monitoring and analyzing usage and activity trends; and',
                '(10) protect the security or integrity of the Services and any facilities or equipment used to make the Services available.',
            ],
            privacy_sub_content2: [
                '(a) to identify the User and to register, administer, verify, deactivate, or manage User account in the Apps;',
                '(b) to facilitate or enable any verification as we consider necessary before we register the User.',
                '(c) to enable the User to provide its services to the Customer;',
                '(d) to process, facilitate, and complete payments due to User on its services has been provided;',
                '(e) to communicate and send the User information related to the provision of its services, including to relay Customer Request to User and to facilitate User’s acceptance of the Request;',
                '(f) to provide the User with a notification and updates on the Apps or changes to our regulation in accordance with which services are to be provided;',
                '(g) to provide the User with a report in relation to its services which have been provided;',
                '(h) to process and respond to the feedback from the User on the service which the User has provided.',
                '(i) to monitor the User activities, behavior, and demographic data including trends and usage of the various services available on the Apps;',
                '(j) for developing, testing, enhancing, personalizing, maintaining and improving our Apps, website and the Services to satisfy the User;',
                '(k) to ensure the safety and security of our Services and all users. This includes:',
                '(l) to resolve customer support issues. For example, we may:',
                '(m) to investigate and resolve claims or disputes, or as allowed or required by applicable law.',
                '(n) for testing, research, analysis and product development. This allows us to understand and analyze User needs and preferences, protect your Personal and/or Business Data, improve and enhance the safety and security of our Services, develop new features, products and services, and facilitate insurance and finance solutions.',
            ],
            privacy_sub_sub_content: {
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
                7: [],
                8: [],
                9: [],
                10: [],
                11: [
                    'i.	Screening drivers and delivery partners before enabling them to use our Services;',
                    'ii. Verifying your identity when you log in our Apps or Website;',
                    'iii. Using device, location, profile, usage and other Personal and/or Business Data to prevent, detect and combat fraud or unsafe activities;',
                    'iv. Monitoring compliance with our Work Rules, policies; and',
                    'v.	Detecting, preventing and taking necessary measures on potential and/or ongoing criminal activities.',
                    'vi. Sharing Driver Partners and Customers’ location when the Emergency Button is activated;',
                ],
                12: [
                    'i.	Investigate and address concerns;',
                    'ii. Monitor and improve our customer support responses;',
                    'iii. Respond to questions, comments and feedback; and',
                    'iv. Inform you about steps taken to resolve customer support issues.',
                ],
                13: [],
                14: [],
            }
        },
        bag3: {
            privacy_content: [
                'We can also use your Personal and/or Business Data when we are required, advised, recommended, expected or requested to do so by our legal advisors or any local or foreign legal, regulatory, governmental or other authority. For example, we may use your Personal and/or Business Data to:',
                'We can use your Personal and/or Business Data for the purposes of advertising and its business partners’ products, services, events or promotions. For example, we may:',
                'We can notify you about such advertisement by post, short messaging service, in-app engagement, online messaging service as well as push notification, by hand and by email. If you wish to unsubscribe to the processing of your Personal and/or Business Data for marketing and promotions, you may click on the unsubscribe link in the relevant email or message. Alternatively, you may also update your preferences in our App settings. The provision of your Personal and/or Business Data to us is on a voluntary basis. However, if you do not provide your Personal and/or Business Data or if insufficient Personal and/or Business Data is provided to us when requested, this may affect our ability to fulfil the Purposes mentioned above and your ability to enjoy the full range of benefits provided by us under the Services.',
                'We may use the User’s Personal and/or Business Data without consent, by relying on Legitimate Interests or other exemptions provided by the applicable data privacy laws, for purposes such as prevention of fraud.'
            ],

            privacy_sub_content: {
                1: [
                    '(1) Comply with court orders or other legal, governmental or regulatory requirements;',
                    '(2) Enforce our Work Rules or other agreements; and',
                    '(3) Protect our rights or property in the event of a claim or dispute.',
                ],
                2: [
                    '(1) Send you alerts, newsletters, updates, mailers, promotional materials, special privileges, festive greetings; and',
                    '(2) Notify, invite and manage your participation in our events or activities.',
                ],
                3: [

                ],
                4: [

                ],
            }
        }

    },

    pvc_part4: {
        bag1: 'We require to share Personal and/or Business Data with the parties for a number of purposes. The parties include:',
        bag2: {
            privacy_content: [
                'Other users of Apps or Website. For example, to the User, we may share the Customer’s name and drop-off location of the purchased items, and the Driver Partner’s name, location, and license plate number. We may share your Personal and/or Business Data with your Customer and the Driver Partner who picks up the order, including your name and photo, your location, items list with pictures and description, items’ prices, and your average rating.',
                'Third parties in connection with a order. For example, we may share your location with third parties when a Customer or Driver Partner activates the Emergency Button.',
                'Subsidiaries and affiliates. We share Personal and/or Business Data with our subsidiaries, associated companies, jointly controlled entities and affiliates.',
                'Business partners of PT TPI, We may provide Personal and/or Business Data to our business partners (independently or at your request). This may include:',
            ],
            privacy_sub_content: {
                1: [],
                2: [],
                3: [],
                4: [
                    '(1) Payment processors and facilitators;',
                    '(2) Background check and anti-money laundering service providers;',
                    '(3) Cloud storage providers;',
                    '(4) Marketing partners and marketing platform providers;',
                    '(5) Data analytics providers;',
                    '(6) Insurance and financial providers;',
                    '(7) Partners that integrate with our App or our App integrates with; and',
                    '(8) Partners which we collaborates with to deliver a promotion, competition or other specialized service.', ,
                ],
            }
        }
    },

    pvc_part5: {
        bag1: '',
        bag2: {
            privacy_content: [
                'We will take all reasonable efforts legally to protect and secure the User’s Personal and/or Business Data against the processing of Personal and/or Business Data by unauthorized persons and against unlawful processing or other similar risk.',
                'We employ the following measures to prevent unauthorized access to the User’s Personal and/or Business Data:',
                'The transmission of information via the Internet is not completely secure, even though we will do our best to protect the Users Personal and/or Business Data, we cannot guarantee the security of the Users Personal and/or Business Data transmitted through any online platform. Thus, any transmission remains at the Users own risk',
            ],
            privacy_sub_content: {
                1: [],
                2: [
                    '(a) Identification of the security threats in the system while processing the Personal and/or Business Data.',
                '(b) Implementing a security system for the premises that contain the information systems in order to prevent unauthorized persons from entering said premises.',
                '(c) Providing for storage of Personal and/or Business Data',
                '(d) Confirming the list of persons who have access to Personal and/or Business Data by virtue of their jobs.',
                '(e) Using the necessary means to protect the Personal and/or Business Data from unauthorized access.',
                '(f) Evaluation the employed measures.',
                '(g) Making the detection of unauthorized access to Personal and/or Business Data possible.',
                '(h) Recovering of the data that has been deleted or corrupted due to unauthorized access (if such recovery is possible).',
                '(i) Enforcing strict regulation on the access to Personal and/or Business Data in the system after their processing.',
                '(j) Controlling the measures employed for securing the Personal and/or Business Data and the level of security of information systems.',
                ],
                3: [],
            }
        }
    },

    pvc_part6: {
        bag1: '',
        bag2: {
            privacy_content: [
                'The User may request us to supplement, block or eliminate the Personal and/or Business Data, if they are incomplete, imprecise, obtained by illegal means or unnecessary for stated goals of the processing. Also, the User can retract his/her consent for processing of that data. This is done through sending of a written request to us with registered mail with the possibility of confirming the delivery or by personal handing in of such requests in the PT TPI premises. The address of our offices is provided on the website. Such requests shall contain the following: The number of the principal identifying document of the User or his/her representative, information regarding the issuance date of such document and its issuer organization, the User’s residence address, information confirming the User and User’s identifying number, or information that confirms in any way the processing of the User’s Personal and/or Business Data, the request for completion, blocking or eliminating of the User’s Personal and/or Business Data or notice for retracting the agreement to process such data, the User’s signature or that of his/her representative. The PT TPI is required to give a justified answer within 30 days since the receipt of such requests or notices.',
                'We will retain your Personal and/or Business Data for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent for a period 5 (five) years in pursuant with applicable law.',
            ],
        }
    },

    pvc_part7: {
        bag1: 'In order to provide services to the Customer, we may share the User’s geographical location information to the Customer and the Driver Partner.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part8: {
        bag1: 'Your Personal and/or Business Data can be stored and processed in any country, state and city where we have facilities or in which we engage service providers. By using the Apps, you have given your consent to the transfer of information to countries outside your country of residence, which can have data protection rules that are different from Indonesia. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in other countries may be entitled to access your Personal and/or Business Data.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part9: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Cookie files are text files, containing a small amount of data that is sent to your browser and stored on your computer, smart phone, and any other device through which users access the Internet. Cookies send some information to the site at any visit to the site. For example, they allow you to save secure search settings, display helpful advertisements, compute the number of visits per page, register for our services, and protect user information. Cookies may be cookie files and can be stored on a computer until they are deleted by the user, or may be temporary (which are called periodic cookies), where they remain in computer until the browser is closed. In addition, cookie files are divided into main files (which are generated directly through site visits) and sub-files (created by other sites).',
                'We may use the following cookie files:',
                'Blocking or deleting cookies, and limiting their activities can be done through the Customer’s browser’s settings.'
            ],
            privacy_sub_content: [
                '(1) Essential cookie files. These cookies are needed to transmit and use the Requested services on the website or with the Apps. These cookies are used when registering the User and entering the system. Without them, the Service requested by the User will be unavailable. These cookies are principle files and can be either transient or persistent. Without them, the website won’t work properly.',
                '(2) Exploitation cookies. These cookies will collect data about website usage statistics. They don’t collect the User’s Personal and/or Business Data. All data collected by them are statistical and unidentifiable. The goal of using them is to:',
                '(3) Operational cookies. These are used to keep the information provided by the User in memory. (For example, User’s name, Language, and Location) These files use anonymous information and do not track the User’s activities on other sites. The goal of using them is to:',
                '(4) Advertising cookies. These cookies are used to  evaluate the performance of advertising agencies. These cookies are used to manage the advertising content on the site. These cookies are put on the site by third parties, for example by third parties and their agents. They can be persistent or transient. These are related to the advertisement on the site that is provided by third parties.',
            ],
            privacy_sub_sub_content: {
                1: [],
                2: [
                    '(a) Obtain the site usage statistics.',
                    '(b) Evaluating the effectiveness of advertising companies.',
                    'These cookies can be persistent and transient and they can be principal or secondary cookie files.',
                ],
                3: [
                    '(a) Keep in memory if certain services have been provided to the Customer.',
                    '(b) Improve the quality of cooperation with the website, usually by saving the priorities of the User.',
                    'These cookies can be persistent and transient and they can be principal or secondary cookie files.',
                ],
                4: [],
                5: [],
                6: [],
                7: [],
                8: [],
                9: [],
                10: [],
            }
        },
    },

    pvc_part10: {
        bag1: 'We receive information about the User’s device and installed applications on it and internet access, through the mobile application. This includes information about the device’s model, operating system, browser information, IP address, and device’s characteristics. This information does not include any Personal and/or Business Data.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part11: {
        bag1: '',
        bag2: {
            privacy_content: [
                'We obtain data about telecommunication operator which provides the Services to the User through the Apps or Website.',
                'Such data does not include User’s Personal and/or Business Data',
                'The goal of obtaining such information is to automatically fill the information about the country of residence of the User and choose the language of the User’s interface in the setting part of the Apps.'
            ],
        }
    },

    pvc_part12: {
        bag1: '',
        bag2: {
            privacy_content: [
                'We store the order records of the User and the Request records of the Customer. These records include the time of initiation of the Request, the addresses to which the vehicle arrives, the destination addresses and the routes taken, the applicable tariff, payment method, and other information provided by the Customer and the User',
                'The goal of collecting such information is to improve the quality of services through auto-filling the parameters of the request by previously provided information to shorten the request time.',
            ],
        }
    },

    pvc_part13: {
        bag1: '',
        bag2: {
            privacy_content: [
                'By accessing and accepting this Privacy Policy, the User acknowledges to have read, understood and agreed with all the terms of this Privacy Policy. Specifically, the User gives us his/her consent to collect, use, share, disclose, store, transfer, or perform any other type of processing of the User’s Personal and/or Business Data in accordance with this Privacy Policy.',
                'In terms of the User providing us with the Personal and/or Business Data relating to other parties, the User represents and warrants to have obtained any kind of consent from an individual for processing of the other party’s Personal and/or Business Data by us.',
                'PThe User may withdraw his/her consent for all processing User’s Personal and/or Business Data, by sending us a reasonable notice in writing. As the consequence, the User is unable to use the Apps along with the Service. After the User have chosen to withdraw his/her consent, we may be able to continue to process the User’s Personal and/or Business Data to the extent required or otherwise permitted by applicable laws and regulations.',
            ],
        }
    },

    pvc_part14: {
        bag1: '',
        bag2: {
            privacy_content: [
                'Without override to the provisions regarding User rights according to applicable laws and regulations, The User is entitled to:',
                'Without override to the provisions regarding Personal and/or Business Data Protection according to applicable laws and regulations, we undertake to:',
            ],
            privacy_sub_content: {
                1: [
                    '(1) Request the correction and/or (in some cases) deletion of your Personal and/or Business Data',
                    '(2) The confidentiality of the Personal and/or Business Data;',
                    '(3) Require us to complete, block or eliminate the data, if they are incomplete, imprecise, obtained by illegal means or unnecessary for stated goals of the processing and also do the necessary activities required by law to protect his rights.',
                ],
                2: [
                    '(1) Protect Personal and/or Business Data from misuse/act of abuse;',
                    '(2) Implement measures to prevent unauthorized access to the User’s Personal and/or Business Data.',
                    '(3) maintain the confidentiality of the Personal and/or Business Data obtained, collected, processed, and analyzed;',
                ],
            }
        }
    },

    pvc_part15: {
        bag1: 'We shall have the right to modify, update or amend the terms of this Policy at any time by placing the updated Policy on the Websites. This Privacy Policy can be changed and/or renewed from time to time without any prior announcement. We suggest to the User to read carefully and check this privacy policy page periodically in order to be aware of any changes thereto. By accessing and using this application, the User agrees with any changes in this Privacy Policy.',
        bag2: {
            privacy_content: [],
        }
    },

    pvc_part16: {
        bag1: 'If you have any queries or complaints relating to your Personal and/or Business Data, wish to make a request for access or correction of your Personal and/or Business Data, or would like to withdraw your consent in respect of the Personal and/or Business Data being processed by us, you can contact us via email or by phone using the following contact details:',
        bag2: {
            privacy_content: [
                'Situs web: https://merchant-id.taximaxim.com/',
                'Email: idmarketplace@taximaxim.com'
            ],
        }
    },

    // text
    // text: {
    //     privacy_policy: [
    //         'I.	General Provisions: ',
    //         'II. Collection of Personal and/or Business Data: ',
    //         'III. The Personal and/or Business Data Usage: ',
    //         'IV. Personal and/or Business Data Disclosure: ',
    //         'V.	Personal and/or Business Data Production: ',
    //         'VI. Supplementing and Elimination Of Personal and/or Business Data: ',
    //         'VII. Geographical Location Information: ',
    //         'VIII. Jurisdiction and Cross Border Transfer: ',
    //         'IX. Cookies: ',
    //         'X. Infomation On Users Device: ',
    //         'XI. Data About Telecommunication Operator: ',
    //         'XII. Request Records: ',
    //         'XIII. The Acknowledgment, The Consent and Its Withdrawal: ',
    //         'XIV. The Users Rights: ',
    //         'XV. The Change Or Updates Of This Policy: ',
    //         'XVI. Contacs: '
    //     ],
    //     privacy_content: {
    //         1: [
    //             '1. «Personal and/or Business Data»: Any information directly and/or indirectly related to you as a User of our Website or Apps, including but not limited to Merchant name, phone number (active Whatsapp account), bank and credit card details (if applicable), email address, description and category of Merchant, geographical location, your image, items and their prices, and descriptions and images of items.',
    //             '2.	«Merchant Partner»:  A person or an entity, who has expressed his/her/its submission to the agreement and has received the right to use the Merchant App, and therefore shall enjoy the rights given and fulfill the responsibilities in the context of providing food and beverages and/or groceries to fulfill the needs of the Client through their orders from the Mobile App. ',
    //             '3.	«Driver Partner»:  A person who independently and on his/her own volition agrees to provide transportation services by motorbike.',
    //             '4.	«Customer»:  A person who has requested via our Customer Apps or website, and has provided his/her Personal Data for this purpose.',
    //             '5.	«The Services»: The information provided to the Customer through our Apps or Website, in order for the Customer’s Request to be accepted, processed and transmitted to the Partners (both Driver and Merchant Partners) and the execution of such Request be communicated back to the Customer. The subject and methods of providing services will be identified according to the customers’ offers on our website.',
    //             '6.	«Processing of Personal and/or Business Data»: any activity or activities done on Personal and/or Business Data, with or without automation, including collection, recording, systematizing, saving, storing, organizing (updating, correcting), abstracting, using, communicating (distributing, providing, accessing), making un-private, blocking, deleting and eliminating Personal and/or Business Data.',
    //             '7.	«Blocking Personal and/or Business Data»: temporary halt in processing of Personal and/or Business Data (excluding instances when processing is necessary to identify Personal and/or Business Data)',
    //             '8.	«Eliminating Personal and/or Business Data»: activities that result in making the existing Personal and/or Business Data unrecoverable and/or result in eliminating the Personal and/or Business Data Storage.'
    //             // '9.	“Personal account” shall mean an account where advance payment of the Merchant Partner and amounts of cash deducted from these payments as payment for granting access to use the Merchant App. The personal account has a unique number and is generated by MAXIM',
    //             // '10. “Personal account balance” shall mean the difference as of certain time-point between the amount of cash deposited on the personal account and deducted from the personal account till this time-point'
    //         ],
    //         2: [
    //             'The User’s Personal and/or Business Data is stored in electronic storage and is processed by automated systems for Processing of Personal and/or Business Data.',
    //             '1. We collect User’s Personal and/or Business Data when the User voluntarily provide it to us. For example, the User may provide his/her Personal and/or Business Data to us when the User:',
    //             '(1) fills in user profile or registration forms;',
    //             '(2) provides information to assess User’s eligibility to provide Services as a Merchant Partner;',
    //             '(3) interacts with our social media pages;',
    //             '(4) participates in contests or events organized by us; and',
    //             '(5) provides demographic information in surveys.',
    //             '2. Personal and/or Business Data may be collected through the normal operation of the Services, such data includes, for example:',
    //             '(1) location;',
    //             '(2) in-Apps transaction information (including the payment method);',
    //             '(3) chat messages when using our in-Apps communication features;',
    //             '(4) feedback, ratings and compliments;',
    //             '(5) the user’s device information;',
    //             '(6) every Personal and/or Business Data which can be captured through User interaction with us.',
    //             '3. When we collect Personal and/or Business Data from other sources, we make sure that that data is transferred to us in accordance with applicable laws. The aforementioned sources include:',
    //             '(1) Referral programs;',
    //             '(2) Our business partners;',
    //             '(3) Publicly available data;',
    //             '4. When the User register and create an account in our website, we collect and process the following Personal and/or Business Data of Partner:',
    //             '(1) Merchant name',
    //             '(2) The registered phone number which also bound to an active Whatsapp account',
    //             '(3) Email',
    //             '(4) Photo of the User’s shop',
    //             '(5) Full information on the User’s shop location',
    //             '(6) Merchant description',
    //             '5. In particular, we may collect and process the device data (GPS location, IMEI number) of the User for analysis and administrative purposes.',
    //             '6. In the following instances the User’s Personal and/or Business Data will be eliminated by us:',
    //             '(1) After three years after completion of providing services;',
    //             '(2) In case the User retracts his/her consent for Processing his/her Personal and/or Business Data.',
    //             '7. The elimination of the Personal and/or Business Data will be done in such a way as to be unrecoverable.',
    //             '8. In the event that any Personal and/or Business Data of a minor (i.e. individuals under the age of 18) is disclosed to us, you hereby signify your consent to the processing of the minor’s Personal and/or Business Data as a parent or legal guardian of the minor (or your agreement to procure the necessary consent from the minor’s parent or legal guardian) and accept and agree to be bound by this Policy and take responsibility for his or her actions. In the event that you are a minor and intend to provide your Personal and/or Business Data to us, you hereby confirm and acknowledge that you have obtained your parent or legal guardian’s agreement to be bound by this Policy.',
    //         ],
    //         3: [
    //             'The main purpose of this Privacy Policy between the User and us is for Processing of Personal and/or Business Data of the User, which is listed below.',
    //             '1. In general, the User’s Personal and/or Business Data will be used to provide, personalize, maintain and improve our Services. This includes using the Personal and/or Business Data to:',
    //             ' (1) provide you with the orders;',
    //             ' (2) engage the Driver Partner in providing the Services;',
    //             ' (3) create, administer and update User’s account;',
    //             ' (4) verify the User’s identity;',
    //             ' (5) process payments;',
    //             ' (6) offer, obtain, provide or facilitate insurance for the order (if applicable);',
    //             ' (7) track the progress of the order;',
    //             ' (8) enable features that personalize your App, such as lists of favorite items according to the Customers',
    //             ' (9) perform internal operations necessary to provide our Services, including troubleshooting software bugs and operational problems, conducting data analysis, testing and research, monitoring and analyzing usage and activity trends; and',
    //             ' (10) protect the security or integrity of the Services and any facilities or equipment used to make the Services available.',
    //             '2. In Specific, we can use the User’s Personal and/or Business Data for the following purposes:',
    //             '(a) to identify the User and to register, administer, verify, deactivate, or manage User account in the Apps;',
    //             '(b) to facilitate or enable any verification as we consider necessary before we register the User.',
    //             '(c) to enable the User to provide its services to the Customer;',
    //             '(d) to process, facilitate, and complete payments due to User on its services has been provided;',
    //             '(e) to communicate and send the User information related to the provision of its services, including to relay Customer Request to User and to facilitate User’s acceptance of the Request;',
    //             '(f) to provide the User with a notification and updates on the Apps or changes to our regulation in accordance with which services are to be provided;',
    //             '(g) to provide the User with a report in relation to its services which have been provided;',
    //             '(h) to process and respond to the feedback from the User on the service which the User has provided.',
    //             '(i) to monitor the User activities, behavior, and demographic data including trends and usage of the various services available on the Apps;',
    //             '(j) for developing, testing, enhancing, personalizing, maintaining and improving our Apps, website and the Services to satisfy the User;',
    //             '(k) to ensure the safety and security of our Services and all users. This includes:',
    //             'i.	Screening drivers and delivery partners before enabling them to use our Services;',
    //             'ii. Verifying your identity when you log in our Apps or Website;',
    //             'iii. Using device, location, profile, usage and other Personal and/or Business Data to prevent, detect and combat fraud or unsafe activities;',
    //             'iv. Monitoring compliance with our Work Rules, policies; and',
    //             'v.	Detecting, preventing and taking necessary measures on potential and/or ongoing criminal activities.',
    //             'vi. Sharing Driver Partners and Customers’ location when the Emergency Button is activated;',
    //             '(l) to resolve customer support issues. For example, we may:',
    //             'i.	Investigate and address concerns;',
    //             'ii. Monitor and improve our customer support responses;',
    //             'iii. Respond to questions, comments and feedback; and',
    //             'iv. Inform you about steps taken to resolve customer support issues.',
    //             '(m) to investigate and resolve claims or disputes, or as allowed or required by applicable law.',
    //             '(n) for testing, research, analysis and product development. This allows us to understand and analyze User needs and preferences, protect your Personal and/or Business Data, improve and enhance the safety and security of our Services, develop new features, products and services, and facilitate insurance and finance solutions.',
    //             '3. We can also use your Personal and/or Business Data when we are required, advised, recommended, expected or requested to do so by our legal advisors or any local or foreign legal, regulatory, governmental or other authority. For example, we may use your Personal and/or Business Data to:',
    //             '(1) Comply with court orders or other legal, governmental or regulatory requirements;',
    //             '(2) Enforce our Work Rules or other agreements; and',
    //             '(3) Protect our rights or property in the event of a claim or dispute.',
    //             '4. We can use your Personal and/or Business Data for the purposes of advertising and its business partners’ products, services, events or promotions. For example, we may:',
    //             '(1) Send you alerts, newsletters, updates, mailers, promotional materials, special privileges, festive greetings; and',
    //             '(2) Notify, invite and manage your participation in our events or activities.',
    //             '5. We can notify you about such advertisement by post, short messaging service, in-app engagement, online messaging service as well as push notification, by hand and by email. If you wish to unsubscribe to the processing of your Personal and/or Business Data for marketing and promotions, you may click on the unsubscribe link in the relevant email or message. Alternatively, you may also update your preferences in our App settings. The provision of your Personal and/or Business Data to us is on a voluntary basis. However, if you do not provide your Personal and/or Business Data or if insufficient Personal and/or Business Data is provided to us when requested, this may affect our ability to fulfil the Purposes mentioned above and your ability to enjoy the full range of benefits provided by us under the Services.',
    //             '6. We may use the User’s Personal and/or Business Data without consent, by relying on Legitimate Interests or other exemptions provided by the applicable data privacy laws, for purposes such as prevention of fraud.',
    //             // '(a) to identify',
    //             // '(a) to identify',
    //             // '(a) to identify',
    //         ],
    //         4: [
    //             'We require to share Personal and/or Business Data with the parties for a number of purposes. The parties include:',
    //             '1. Other users of Apps or Website. For example, to the User, we may share the Customer’s name and drop-off location of the purchased items, and the Driver Partner’s name, location, and license plate number. We may share your Personal and/or Business Data with your Customer and the Driver Partner who picks up the order, including your name and photo, your location, items list with pictures and description, items’ prices, and your average rating.',
    //             '2. Third parties in connection with a order. For example, we may share your location with third parties when a Customer or Driver Partner activates the Emergency Button.',
    //             '3. Subsidiaries and affiliates. We share Personal and/or Business Data with our subsidiaries, associated companies, jointly controlled entities and affiliates.',
    //             '4. Business partners of PT TPI, We may provide Personal and/or Business Data to our business partners (independently or at your request). This may include:',
    //             '(1) Payment processors and facilitators;',
    //             '(2) Background check and anti-money laundering service providers;',
    //             '(3) Cloud storage providers;',
    //             '(4) Marketing partners and marketing platform providers;',
    //             '(5) Data analytics providers;',
    //             '(6) Insurance and financial providers;',
    //             '(7) Partners that integrate with our App or our App integrates with; and',
    //             '(8) Partners which we collaborates with to deliver a promotion, competition or other specialized service.',

    //         ],
    //         5: [
    //             '1. We will take all reasonable efforts legally to protect and secure the User’s Personal and/or Business Data against the processing of Personal and/or Business Data by unauthorized persons and against unlawful processing or other similar risk.',
    //             '2. We employ the following measures to prevent unauthorized access to the User’s Personal and/or Business Data:',
    //             '(1) Employing staff in charge of organizing the processing of the Personal and/or Business Data.',
    //             '(2) Implementing organizational and technical measures to ensure the security of the User’s Personal and/or Business Data. Such measures are:',
    //             '(a) Identification of the security threats in the system while processing the Personal and/or Business Data.',
    //             '(b) Implementing a security system for the premises that contain the information systems in order to prevent unauthorized persons from entering said premises.',
    //             '(c) Providing for storage of Personal and/or Business Data',
    //             '(d) Confirming the list of persons who have access to Personal and/or Business Data by virtue of their jobs.',
    //             '(e) Using the necessary means to protect the Personal and/or Business Data from unauthorized access.',
    //             '(f) Evaluation the employed measures.',
    //             '(g) Making the detection of unauthorized access to Personal and/or Business Data possible.',
    //             '(h) Recovering of the data that has been deleted or corrupted due to unauthorized access (if such recovery is possible).',
    //             '(i) Enforcing strict regulation on the access to Personal and/or Business Data in the system after their processing.',
    //             '(j) Controlling the measures employed for securing the Personal and/or Business Data and the level of security of information systems.',
    //             '3. The transmission of information via the Internet is not completely secure, even though we will do our best to protect the Users Personal and / or Business Data, we cannot guarantee the security of the Users Personal and/or Business Data transmitted through any online platform. Thus, any transmission remains at the Users own risk.',
    //         ],
    //         6: [
    //             'The User may request us to supplement, block or eliminate the Personal and/or Business Data, if they are incomplete, imprecise, obtained by illegal means or unnecessary for stated goals of the processing. Also, the User can retract his/her consent for processing of that data. This is done through sending of a written request to us with registered mail with the possibility of confirming the delivery or by personal handing in of such requests in the PT TPI premises. The address of our offices is provided on the website. Such requests shall contain the following: The number of the principal identifying document of the User or his/her representative, information regarding the issuance date of such document and its issuer organization, the User’s residence address, information confirming the User and User’s identifying number, or information that confirms in any way the processing of the User’s Personal and/or Business Data, the request for completion, blocking or eliminating of the User’s Personal and/or Business Data or notice for retracting the agreement to process such data, the User’s signature or that of his/her representative. The PT TPI is required to give a justified answer within 30 days since the receipt of such requests or notices.',
    //             'We will retain your Personal and/or Business Data for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent for a period 5 (five) years in pursuant with applicable law.',
    //         ],
    //         7: [
    //             'In order to provide services to the Customer, we may share the User’s geographical location information to the Customer and the Driver Partner.',
    //         ],
    //         8: [
    //             'Your Personal and/or Business Data can be stored and processed in any country, state and city where we have facilities or in which we engage service providers. By using the Apps, you have given your consent to the transfer of information to countries outside your country of residence, which can have data protection rules that are different from Indonesia. In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in other countries may be entitled to access your Personal and/or Business Data.',
    //         ],
    //         9: [
    //             '1. Cookie files are text files, containing a small amount of data that is sent to your browser and stored on your computer, smart phone, and any other device through which users access the Internet. Cookies send some information to the site at any visit to the site. For example, they allow you to save secure search settings, display helpful advertisements, compute the number of visits per page, register for our services, and protect user information. Cookies may be cookie files and can be stored on a computer until they are deleted by the user, or may be temporary (which are called periodic cookies), where they remain in computer until the browser is closed. In addition, cookie files are divided into main files (which are generated directly through site visits) and sub-files (created by other sites).',
    //             '2. We may use the following cookie files:',
    //             '(1) Essential cookie files. These cookies are needed to transmit and use the Requested services on the website or with the Apps. These cookies are used when registering the User and entering the system. Without them, the Service requested by the User will be unavailable. These cookies are principle files and can be either transient or persistent. Without them, the website won’t work properly.',
    //             '(2) Exploitation cookies. These cookies will collect data about website usage statistics. They don’t collect the User’s Personal and/or Business Data. All data collected by them are statistical and unidentifiable. The goal of using them is to:',
    //             '(a) Obtain the site usage statistics.',
    //             '(b) Evaluating the effectiveness of advertising companies.',
    //             'These cookies can be persistent and transient and they can be principal or secondary cookie files.',
    //             '(3) Operational cookies. These are used to keep the information provided by the User in memory. (For example, User’s name, Language, and Location) These files use anonymous information and do not track the User’s activities on other sites. The goal of using them is to:',
    //             '(a) Keep in memory if certain services have been provided to the Customer.',
    //             '(b) Improve the quality of cooperation with the website, usually by saving the priorities of the User.',
    //             'These cookies can be persistent and transient and they can be principal or secondary cookie files.',
    //             '(4) Advertising cookies. These cookies are used to  evaluate the performance of advertising agencies. These cookies are used to manage the advertising content on the site. These cookies are put on the site by third parties, for example by third parties and their agents. They can be persistent or transient. These are related to the advertisement on the site that is provided by third parties. ',
    //             '3. Blocking or deleting cookies, and limiting their activities can be done through the Customer’s browser’s settings.',
    //         ],
    //         10: [
    //             'We receive information about the User’s device and installed applications on it and internet access, through the mobile application. This includes information about the device’s model, operating system, browser information, IP address, and device’s characteristics. This information does not include any Personal and/or Business Data.',
    //         ],
    //         11: [
    //             '1. We obtain data about telecommunication operator which provides the Services to the User through the Apps or Website',
    //             '2. Such data does not include User’s Personal and/or Business Data.',
    //             '3. The goal of obtaining such information is to automatically fill the information about the country of residence of the User and choose the language of the User’s interface in the setting part of the Apps.',
    //         ],
    //         12: [
    //             '1. We store the order records of the User and the Request records of the Customer. These records include the time of initiation of the Request, the addresses to which the vehicle arrives, the destination addresses and the routes taken, the applicable tariff, payment method, and other information provided by the Customer and the User.',
    //             '2. The goal of collecting such information is to improve the quality of services through auto-filling the parameters of the request by previously provided information to shorten the request time.',
    //         ],
    //         13: [
    //             '1. By accessing and accepting this Privacy Policy, the User acknowledges to have read, understood and agreed with all the terms of this Privacy Policy. Specifically, the User gives us his/her consent to collect, use, share, disclose, store, transfer, or perform any other type of processing of the User’s Personal and/or Business Data in accordance with this Privacy Policy.',
    //             '2. In terms of the User providing us with the Personal and/or Business Data relating to other parties, the User represents and warrants to have obtained any kind of consent from an individual for processing of the other party’s Personal and/or Business Data by us.',
    //             '3. The User may withdraw his/her consent for all processing User’s Personal and/or Business Data, by sending us a reasonable notice in writing. As the consequence, the User is unable to use the Apps along with the Service. After the User have chosen to withdraw his/her consent, we may be able to continue to process the User’s Personal and/or Business Data to the extent required or otherwise permitted by applicable laws and regulations.',
    //         ],
    //         14: [
    //             '1. Without override to the provisions regarding User rights according to applicable laws and regulations, The User is entitled to:',
    //             '(1) Request the correction and/or (in some cases) deletion of your Personal and/or Business Data;',
    //             '(2) The confidentiality of the Personal and/or Business Data;',
    //             '(3) Require us to complete, block or eliminate the data, if they are incomplete, imprecise, obtained by illegal means or unnecessary for stated goals of the processing and also do the necessary activities required by law to protect his rights.',
    //             '2. Without override to the provisions regarding Personal and/or Business Data Protection according to applicable laws and regulations, we undertake to:',
    //             '(1) Protect Personal and/or Business Data from misuse/act of abuse;',
    //             '(2) Implement measures to prevent unauthorized access to the User’s Personal and/or Business Data.',
    //             '(3) maintain the confidentiality of the Personal and/or Business Data obtained, collected, processed, and analyzed;',
    //         ],
    //         15: [
    //             'We shall have the right to modify, update or amend the terms of this Policy at any time by placing the updated Policy on the Websites. This Privacy Policy can be changed and/or renewed from time to time without any prior announcement. We suggest to the User to read carefully and check this privacy policy page periodically in order to be aware of any changes thereto. By accessing and using this application, the User agrees with any changes in this Privacy Policy.',
    //         ],
    //         16: [
    //             'If you have any queries or complaints relating to your Personal and/or Business Data, wish to make a request for access or correction of your Personal and/or Business Data, or would like to withdraw your consent in respect of the Personal and/or Business Data being processed by us, you can contact us via email or by phone using the following contact details:',
    //             'Website: https://merchant-id.taximaxim.com/',
    //             'Email: idmarketplace@taximaxim.com',
    //             'The original of this Policy is written in the English language. In the event of any conflict between the English and other language versions, the English version shall prevail.'
    //         ],
    //     },
    // },
}

export default privacy