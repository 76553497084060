import { useMediaQuery } from "react-responsive"
import LandingPageDesktop from "./landingPageDesktop"
import LandingPageMobile from "./landingPageMobile"

const LandingPage = () => {
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
    })
    return(
        <>
        {mobile ? <LandingPageMobile/> : <LandingPageDesktop/>}
        </>
    )
}

export default LandingPage