const INITIAL_STATE = {
    name:'',
    email: '',
    password: '',
    password_confirmation:'',
    cities_id: null,
    desc: '',
    phone: '',
    lat: -6.177001581818966,
    lon: 106.82716516100207,
    district_id: null,
    address: '',
    ref:null,
    data_cities: [],
    data_district: [],
    responses: null,
    page: 1
}

const registerReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'DATADETAIL': 
            return{
                ...state,
                name: action.payload.name,
                email: action.payload.email,
                password: action.payload.password,
                password_confirmation: action.payload.password_confirmation,
                desc: action.payload.desc,
                phone: action.payload.phone,
                ref: action.payload.ref,
                page: 2
            }
        case 'LOCTDETAIL':
            return{
                ...state,
                address: action.payload.address,
                cities_id: action.payload.cities_id,
                district_id: action.payload.district_id,
                page:3
            }
        case 'POSITION' : 
            return{
                ...state,
                lat: action.payload.lat,
                lon: action.payload.lng
            }
        case 'DATA_CITIES' : 
            return {
                ...state, 
                data_cities: action.payload
            }
        case 'DATA_DISTRICT' :
            return{
                ...state,
                data_district: action.payload
            }
        case `RES_REG`:
            return{
                ...state,
                responses: action.payload
            }
        case 'BACKBUTTON':
            return{
                ...state,
                page: state.page - 1
            }
        case 'REMOVERES':
            return{
                ...state,
                responses: null
            }
        case 'COMPLETE':
            return{
                ...state,
                page: 4
            }
        default:
            return state
    }
}

export default registerReducer