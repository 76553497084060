import axios from "axios"
const URL = 'https://brmarket-api.taximaxim.com/api/v1/merchant'

export const fetchItem_Categories = (token) => {
    return async (dispatch) => {
        try {
            // const token = localStorage.getItem('access_token')
            const res = await axios.get(`${URL}/item-category`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            })
            if (res.data.status) {
                dispatch({
                    type: 'ERRFETCH',
                    payload: 'error fetch data'
                })
            } else {
                // console.log(res.data.result)
                dispatch({
                    type: 'FETCHCATEGORY',
                    payload: res.data.result
                })
            }
        }
        catch (err) {
            // console.log(err.response)
            dispatch({
                type: 'ERRFETCH',
                payload: 'error fetch data'
            })
        }
    }
}

export const createItem = async (token, data) => {
    try {
        // const token = localStorage.getItem('access_token')
        const bodyFormData = new FormData();
        Object.keys(data).forEach(key => {
            if (key == 'image') {
                bodyFormData.append('image', data.image)
            }
            else {
                bodyFormData.append(key, data[key])
            }
        });
        bodyFormData.append('total', (data.price - data.discount_amount));

        const res = await axios.post(`${URL}/item/create`, bodyFormData, {
            headers: { Authorization: `Bearer ${token}` },
            'Content-Type': `multipart/form-data; boundary=${bodyFormData._boundary}; charset=UTF-8`
        });
        
        if(res.status === 200) return true
        else return false
    }
    catch (err) {
        // console.log(err);
        return false
    }

}

export const editItem = async(token, data, itemId) => {
    try {
        // const token = localStorage.getItem('access_token')
        const bodyFormData = new FormData();
        Object.keys(data).forEach(key => {
            if (key == 'image') {
                bodyFormData.append('image', data.image)
            }
            else {
                bodyFormData.append(key, data[key])
            }
        });
        bodyFormData.append('total', (data.price - data.discount_amount));

        await axios.post(`${URL}/item/update/` + itemId, bodyFormData, {
            headers: { Authorization: `Bearer ${token}` },
            'Content-Type': `multipart/form-data; boundary=${bodyFormData._boundary}; charset=UTF-8`
        });
        
        return true
    }
    catch (err) {
        // console.log(err);
        return false
    }
}