import { t, t_agree } from '../../misc/utils'

//css
import './style.css'

// Component
import {useMediaQuery} from 'react-responsive'
import { Button } from '@mui/material'
import { Language } from '@mui/icons-material'
import { LanguageComponent } from '..'

// Assets
import BG from '../../assets/image/bg-d_1.svg'
import SPLASH from '../../assets/image/splash_1.svg'
const MerchantAgreement = () => {
    const SearchParams = new URLSearchParams(window.location.search)
    const params = SearchParams.get('from')
    const translate = localStorage.getItem('language')
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
      })

    return (
        <div>
            <div className="box-left">
                <div className="top-content">
                    <Language className="icon-world" />
                    <LanguageComponent value={translate}/>
                </div>
                <div className="middle-content">
                    <h1>{t.title.agreement}</h1>
                    <h2>{t.subTitle.agreement}</h2>
                    <div>
                        {
                           t_agree.agreement.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <h3>{item}</h3>
                                            {t_agree.agreement_content[index + 1].map((itm, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <h3 style={{ marginLeft: 10 }}>{itm}</h3>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })
                        }
                        {/* <h3> <b>Read more about : <a href="/privacypolicy?from=register">Privacy and Policy</a></b></h3> */}
                    </div>
                    {
                        params === 'register' ?
                        <div className="middle-content-btn">
                            <Button variant='outlined' className="btn-back" href='/register'>{t.btn.Back}</Button>
                            <Button variant='contained' className="btn-go" href='/privacypolicy?from=agreement'>{t.btn.Acc}</Button>
                        </div>  
                        :
                        params === 'pvc' ?
                        <div className="middle-content-btn">
                            <Button variant='outlined' className="btn-back" href='/privacypolicy?from=register' >{t.btn.Back}</Button>
                            <Button variant='contained' className="btn-go" href='/register/reg'>{t.btn.Acc}</Button>
                        </div>                  
                        :
                        <></>
                    }
                </div>
            </div>
            {mobile ? <></> : 
            <div className="bottom-content" style={{backgroundImage: "url(" + BG + ")", backgroundSize: 'cover'}}>
                <img src={SPLASH} />
            </div>}
        </div>
    )
}

export default MerchantAgreement