import React from 'react'
import { t, process_image } from '../../../misc/utils'
import { RegisterMerchant, backPage, removeRes } from '../../../application/action'
import { useSelector, useDispatch } from 'react-redux'

// Component
import { Button } from '@mui/material'
import { Modal } from "react-bootstrap"
import {LanguageComponent} from '../../'
import {useMediaQuery} from 'react-responsive'
import { Language } from '@mui/icons-material'
import Reactloading from 'react-loading';

// Assets
import MerchantIMG from '../../../assets/image/merchant.png'
import BG from '../../../assets/image/bg-d_1.svg'
import SPLASH from '../../../assets/image/splash_1.svg'

const RegisterMerchantPic = () => {
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
      })
    const dispatch = useDispatch()
    const [imageShow, setImageShow] = React.useState(MerchantIMG)
    const [image, setImage] = React.useState({})
    const translate = localStorage.getItem('language')
    const [err, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [timeoutRes, setTimeoutRes] = React.useState(false)
    const [timeOut, setTimeOut] = React.useState(false)

    // const [ok, setOk] = React.useState(false)
    React.useEffect(() => {
        console.log(loading);
        if(loading === true){
            setTimeout(() => {
                if(loading === true){
                    console.log('asdwe')
                    setLoading(false)
                    setTimeOut(true)
                }
            }, 60000);
        }
        if(timeOut) {
            console.log('asdwea1');
            setTimeoutRes(true)
        }
    }, [loading, timeOut])

    const {
        name,
        email,
        password,
        password_confirmation,
        cities_id,
        desc,
        phone,
        lat,
        lan,
        district_id,
        address,
        ref,
        responses,
        page
    } = useSelector((state) => {
        return {
            name: state.Register.name,
            email: state.Register.email,
            password: state.Register.password,
            password_confirmation: state.Register.password_confirmation,
            cities_id: state.Register.cities_id,
            desc: state.Register.desc,
            phone: state.Register.phone,
            lat: state.Register.lat,
            lan: state.Register.lon,
            district_id: state.Register.district_id,
            address: state.Register.address,
            ref: state.Register.ref,
            responses: state.Register.responses,
            page: state.Register.page
        }
    })
    // with compression
    // const handleChoose = async(e) => {
    //     let index = Object.keys(e.target.files)
    //     try{
    //         if (e.target.files[0]) {
    //             const urlImage = await process_image(index, e.target.files, 'CREATE_URL')
    //             const compressedImage = await process_image(index, e.target.files, 'IMG_PROCESSING')
    //             setImage(compressedImage[0])
    //             setImageShow(urlImage[0])
    //         }
    //         else setImageShow('')

    //     }
    //     catch(err){
    //         console.log(err)
    //     }
    // }

    const handleChoose = (e) => {
        let img = e.target.files[0]
        if (img) {
            setImageShow(URL.createObjectURL(img))
            setImage(img)
        }
        else setImageShow('')
    }

    const submit = () => {
        if (imageShow === MerchantIMG) setError(t.err.nopic)
        else {
            const data = {
                name: name,
                email: email,
                password: password,
                password_confirmation: password_confirmation,
                cities_id: cities_id,
                desc: desc,
                phone: phone,
                lat: lat,
                lon: lan,
                districts_id: district_id,
                image: image,
                address: address,
                ref:ref?ref:'',
            }
            setLoading(true)
            dispatch(RegisterMerchant(data))
            // setOk(true)
        }
    }
    if(page === 4) setLoading(false)
    // if(ok) return(<Redirect to='/registerdone'/>)
    return (
        <div>
            <div style={{
                float: 'left', width: mobile ? '100%' : '50%',
                margin: 'auto',
                padding: 30
            }} >
                <div style={{ color: '#000000', borderColor: '#000000', borderRadius: 20, fontSize: 20, float: 'right', padding: 8, width: 200, display: 'flex', flexDirection: 'row' }}>
                    <Language style={{ margin: 10 }} />
                    <LanguageComponent style={{ width: 90 }} value={translate}/>
                </div>
                <div style={{ marginTop: 100 }}>
                    <h1 style={{ fontSize: 18, fontWeight: 500 }}>{t.title.regis}</h1>
                    <h1 style={{ fontSize: 16, fontWeight: 300 }}>{t.subTitle.pic}</h1>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                        <img src={imageShow} style={{ maxWidth: 334, height: 243, border: '1px solid black' }} />
                        <label htmlFor="upload-photo">
                            <form encType='multipart/form-data'>
                                <input
                                    src={MerchantIMG}
                                    style={{ display: 'none' }}
                                    id="upload-photo"
                                    name="IMG"
                                    type="file"
                                    accept='image/*'
                                    onChange={(e) => handleChoose(e)}
                                />
                            </form>
                            <Button style={{ fontWeight: 500, width: 190, fontSize: 14, borderRadius: 10, marginTop: 30, marginBottom: 20, backgroundColor: '#D02C48' }} variant="contained" component="span">
                                {t.btn.Pic}
                            </Button>
                        </label>
                    </div>
                    <div style={{ float: 'right' }}>
                        <Button variant='outlined' style={{ borderColor: '#FF6000', color: '#FF6000', fontSize: 16, width: 120, fontWeight: 'bold', height: 50, borderRadius: 30 }} onClick={() => dispatch(backPage())} >{t.btn.Back}</Button>
                        <Button variant="contained" style={{ marginLeft: 10, padding: 10, backgroundColor: '#FF6000', color: 'white', fontWeight: 'bold', width: 120, height: 50, fontSize: 16, borderRadius: 30 }} onClick={submit}>{t.btn.Submit}</Button>
                    </div>
                </div>
            </div>
            {mobile ? <></> : <div style={{
                float: 'right', width: '50%', height: '100vh',
                backgroundImage: "url(" + BG + ")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'fixed',
                right: 0
            }}>
                <img src={SPLASH} style={{ right: 0, bottom: 0, position: "fixed" }} />
            </div>}

            {/* error */}
            <Modal show={err} onHide={() => setError(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error !</Modal.Title>
                </Modal.Header>
                <Modal.Body>{err}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setError(null)}>
                        {t.btn.Close}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={responses || timeoutRes} onHide={() => {
                dispatch(removeRes())
                setLoading(false)
                setTimeoutRes(false)
                setTimeOut(false)
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Error !</Modal.Title>
                </Modal.Header>
                <Modal.Body>{responses === 'Error 404' ? t.err.sent : responses === "The email has already been taken." ? t.err.emailsent : t.err.sent }</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        dispatch(removeRes())
                        setLoading(false)
                        setTimeoutRes(false)
                        setTimeOut(false)
                     }}>
                        {t.btn.Close}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* loading */}
            <Modal show={loading} centered>
                <Modal.Body>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <Reactloading type='spin' color='red' height={70} width={70} />
                    <h1 style={{ fontSize: 30, fontWeight: 500, marginTop:15, marginLeft:20 }}>Loading ...</h1>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default RegisterMerchantPic