import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createItem, fetchItem_Categories } from '../../../application/action';
import { process_image_crop, t } from '../../../misc/utils';

// Component
import { Button, TextField, Autocomplete } from '@mui/material';
import { Language } from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'
import { LanguageComponent } from '../../';
import Cropper from "react-cropper";
import ReactLoading from 'react-loading'
import { Modal } from 'react-bootstrap';

// Assets
import BG from '../../../assets/image/bg-d_1.svg'
import SPLASH from '../../../assets/image/splash_1.svg'
import ItemImg from '../../../assets/image/item_image.png'

const ProductAdd = (props) => {
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
    })
    const dispatch = useDispatch()
    const translate = localStorage.getItem('language')
    const status = [t.option.yes, t.option.no]
    const [item, setItem] = React.useState({
        price: ''
    })
    const [ImagePreview, setImagePreview] = React.useState(ItemImg)
    const [cropper, setCropper] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const [errImage, setErrImage] = React.useState(false)
    const [stringPrice, setStringPrice] = React.useState()
    const [stringDisc, setStringDisc] = React.useState()

    const token = new URLSearchParams(props.location.search).get('token')
    // console.log(token);

    React.useEffect(() => {
        dispatch(fetchItem_Categories(token))
    }, [])
    const { category } = useSelector((state) => {
        return {
            category: state.Product.category
        }
    })
    // console.log(category);
    const handleItemForm = (key, value) => {
        if (key != 'image_preview') {
            setItem(prevState => ({
                ...prevState,
                [key]: value
            }));
        }
        else setImagePreview(value)

        // console.log(item)
    }
    const handleChoose = (e) => {
        let img = e.target.files[0]
        // console.log(img);
        if (img) {
            handleItemForm('image_preview', URL.createObjectURL(img))
            handleItemForm('image', img)
        }
        else {
            setImagePreview(ItemImg)
            delete item['image']
        }
    }
    const handleSubmit = async () => {
        if (!item.name || !item.item_categories_id || !item.item_category || item.price === undefined || item.discount_amount === undefined || item.is_rec === undefined || item.discount === undefined || !item.desc) alert(t.err.notfilled)
        else if (ImagePreview === ItemImg) alert(t.err.nopic)
        else {
            if(item.discount === 1 && item.discount_amount <= 0) return alert(t.err.notfilled)
            await setLoading(true)
            const getCropImage = cropper.getCroppedCanvas()
            if(!getCropImage) {
                await setLoading(false)
                await setErrImage(true)
            }
            const file_image_crop = await dataURLtoFile(getCropImage.toDataURL(), Date.now());
            // const compressed = await process_image_crop(['0'], file_image_crop, 'IMG_PROCESSING')
            const data = {
                ...item,
                image: file_image_crop
            }
            // console.log(data, 'test');
            const res = await createItem(token, data)
            if (res) {
                await setLoading(false)
                await window.open('/complete_add', '_self')
            }
            else console.log(res);
        }
    }
    function dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }
    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }
    const onKeyDown= (e, type) => {
        if(stringPrice || stringDisc) {
            // e.target.select()
            if(type === 'price') setStringPrice()
            if(type === 'disc') setStringDisc()
        }
    }
    const OnBlur = (e, type) => {
        if(type === 'price') setStringPrice(e.target.value.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,"))
        if(type === 'disc') setStringDisc(e.target.value.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&,"))
    }

    return (
        <div>
            <div style={{
                float: 'left', width: mobile ? '100%' : '50%',
                margin: 'auto',
                padding: 30
            }}>
                <div style={{ color: '#000000', borderColor: '#000000', borderRadius: 20, fontSize: 20, float: 'right', padding: 8, width: 200, display: 'flex', flexDirection: 'row' }}>
                    <Language style={{ margin: 10 }} />
                    <LanguageComponent value={translate} />
                </div>
                <div style={{ marginTop: 100 }}>
                    <h1 style={{ fontSize: 18, fontWeight: 500 }}>{t.title.product_add}</h1>
                    {/* <h1 style={{ fontSize: 16, fontWeight: 300 }}>{translate === 'en' ? en.st_detail : id.st_detail}</h1> */}
                    <div className='Form'>
                        <TextField id="outlined-basic" autoComplete='off' label={t.form.product_name} variant="outlined" color='warning' style={styles.formField} onChange={(e) => handleItemForm('name', e.target.value)} />
                        <TextField id="outlined-basic" autoComplete='off' label={t.form.desc} variant="outlined" color='warning' style={styles.formField} onChange={(e) => handleItemForm('desc', e.target.value)} />
                        <Autocomplete
                            disablePortal
                            options={category}
                            getOptionLabel={(options) => options ? options.name : ''}
                            onChange={(e, newValue) => {
                                // console.log(e)
                                setItem((prev) => {
                                    return {
                                        ...prev,
                                        item_categories_id: newValue.id,
                                        item_category: {
                                            id: newValue.id,
                                            created_at: newValue.created_at,
                                            deleted_at: newValue.deleted_at,
                                            updated_at: newValue.updated_at,
                                            name: newValue.name,
                                            merchant_id: newValue.merchant_id
                                        }
                                    }
                                })
                            }}
                            renderInput={(params) => <TextField {...params} id="outlined-basic" color='warning' style={styles.formField} label={t.form.product_category} />}
                        />
                        <TextField id="outlined-basic" onBlur={(e) => OnBlur(e, 'price')} autoComplete='off' type='text' onKeyDown={(e) => onKeyDown(e, 'price')} onKeyUp={onKeyUp} label={t.form.product_price} value={stringPrice ? stringPrice : item?.price} variant="outlined" color='warning' style={styles.formField} onChange={(e) => handleItemForm('price', e.target.value)}
                        />
                        <Autocomplete
                            disablePortal
                            options={status}
                            onChange={(e, newValue) => setItem((prev) => {
                                return {
                                    ...prev,
                                    discount: newValue === t.option.yes ? 1 : 0,
                                    discount_amount: newValue === t.option.no ? 0 : 0
                                }
                            })}
                            renderInput={(params) => <TextField {...params} id="outlined-basic" color='warning' style={styles.formField} label={t.form.product_disc} />}
                        />
                        <TextField id="outlined-basic" onBlur={(e) => OnBlur(e, 'disc')} autoComplete='off' onKeyDown={(e) => onKeyDown(e, 'disc')} onKeyUp={onKeyUp}  value={stringDisc ? stringDisc : item.discount_amount} disabled={item?.discount === 0 || item?.discount === undefined} type='text' label={t.form.product_discamnt} variant="outlined" color='warning' style={styles.formField} onChange={(e) => handleItemForm('discount_amount', e.target.value)} />
                        <Autocomplete
                            disablePortal
                            options={status}
                            onChange={(e, newValue) => setItem((prev) => {
                                return {
                                    ...prev,
                                    is_rec: newValue === t.option.yes ? 1 : 0
                                }
                            })}
                            renderInput={(params) => <TextField {...params} id="outlined-basic" color='warning' style={styles.formField} label={t.form.product_rec} />}
                        />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {ImagePreview === ItemImg ? 
                                 <img src={ImagePreview} style={{ maxWidth: 334, height: 243, border: '1px solid black', objectFit:'contain' }} />
                                 :
                                 <Cropper
                                     style={{ height: 260, width: 260, margin:'auto' }}
                                     // zoomTo={0.1}
                                     initialAspectRatio={1}
                                     preview=".img-preview"
                                     src={ImagePreview}
                                     viewMode={2}
                                     cropBoxResizable={false}
                                     background={false}
                                     responsive={true}
                                     autoCropArea={1}
                                     disabled={true}
                                     center={true}
                                     dragMode='move'
                                     checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                     onInitialized={(instance) => {
                                       setCropper(instance);
                                     }}
                                     guides={true}
                                 />
                            }
                            <label htmlFor="upload-photo">
                                <form encType='multipart/form-data'>
                                    <input
                                        src={ItemImg}
                                        style={{ display: 'none' }}
                                        id="upload-photo"
                                        name="IMG"
                                        type="file"
                                        accept='.jpg, .jpeg, .png'
                                        onChange={(e) => handleChoose(e)}
                                    />
                                </form>
                                <Button style={{ fontWeight: 500, width: '100%', fontSize: 14, borderRadius: 10, marginTop: 30, marginBottom: 20, backgroundColor: '#D02C48' }} variant="contained" component="span">
                                    {t.btn.Pic}
                                </Button>
                            </label>

                        </div>
                    </div>
                    <div style={styles.button_container}>
                        <Button variant="contained" style={{ marginLeft: 10, padding: 10, backgroundColor: '#FF6000', color: 'white', fontWeight: 'bold', width: 120, height: 50, fontSize: 16, borderRadius: 30 }} onClick={handleSubmit}>{t.btn.Submit}</Button>
                    </div>
                </div>
            </div>
            {mobile ? <></> : <div style={{
                float: 'right', width: '50%', height: '100vh',
                backgroundImage: "url(" + BG + ")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'fixed',
                right: 0
            }}>
                <img src={SPLASH} style={{ right: 0, bottom: 0, position: "fixed" }} />
            </div>}
            <Modal show={loading} centered>
                <Modal.Body>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    <ReactLoading type='spin' color='red' height={70} width={70} />
                    <h1 style={{ fontSize: 30, fontWeight: 500, marginTop:15, marginLeft:20 }}>Loading ...</h1>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal show={errImage} centered onHide= {() => setErrImage(false)}  >
                <Modal.Body>
                    <div>
                        {t.err.picFail}
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ border:0 }}>
                    <Button variant="secondary" onClick={() => setErrImage(false)}>
                        {t.btn.Close}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ProductAdd

const styles = {
    formField: {
        marginTop: 10,
        // marginLeft: 10,
        marginBottom: 10,
        width: '100%',
        borderRadius: 60
    },
    button_container: {
        float: 'right',
    }
}