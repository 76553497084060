import { t } from '../../../misc/utils' 
import React from 'react'

// Component
import {useMediaQuery} from 'react-responsive'
import { Button } from '@mui/material'
import { Language } from '@mui/icons-material'
import { LanguageComponent } from "../.."

// Assets
import BG from '../../../assets/image/bg-d_1.svg'
import SPLASH from '../../../assets/image/splash_1.svg'
import GROUPEN from '../../../assets/image/Group_regEN.svg'
import GROUPID from '../../../assets/image/Group_regID.svg'
const Home = () => {
    const [agree, setAgree] = React.useState(false)
    const translate = localStorage.getItem('language')
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
      })
    
    //   console.log(t);

    return (
        <div>
            <div style={{
                float: 'left', width: mobile ? '100%' : '50%',
                margin: 'auto',
                padding: 30
            }}>
                {/* <Button style={{ color: '#000000', borderColor: '#000000', borderRadius: 15, float: 'right' }} variant='outlined'><Language style={{ marginRight: 10 }} /> {translate === 'en' ? en.btnLanguage : id.btnLanguage}</Button> */}
                <div style={{ color: '#000000', borderColor: '#000000', borderRadius: 20, fontSize: 20, float: 'right', padding: 8, width: 200, display:'flex', flexDirection:'row' }}>
                    <Language style={{margin: 10}} />
                    <LanguageComponent style={{ width: 90 }} value={translate}/>
                </div>
                <div style={{ marginTop: 100, textAlign: 'center', }}>
                    <h1 style={{ fontSize: 22, fontWeight: 400, marginBottom: 30 }} >{t.title.regis}</h1>
                    <h1 style={{ fontSize: 25, fontWeight: 500, marginTop: 20, maxWidth: 397, margin: 'auto' }} >{t.subTitle.regis}</h1>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Button href='/register/reg' disabled={!agree} style={ agree ? { fontWeight: 500, width: 190, fontSize: 14, height:48, margin: 'auto', borderRadius: 10, marginTop: 60, marginBottom: 20, backgroundColor: '#D02C48' } : { fontWeight: 500, width: 190, fontSize: 14, height:48, margin: 'auto', borderRadius: 10, marginTop: 60, marginBottom: 20, backgroundColor: '#DDDDDD' }} variant='contained'>{t.btn.Register}</Button>
                       <label style={{ marginBottom:50 }}>
                           <input type='checkbox' style={{ marginRight:10 }} onChange={(e) => setAgree(e.target.checked)}  autoComplete='off'/> 
                           <span>
                            {t.text.checkAgree.agree}
                            <a href='/merchant-agreement?from=register' style={{ marginLeft:5, marginRight:5 }}>
                                {t.text.checkAgree.merchAgree}
                            </a>
                            {t.text.checkAgree.and}
                            <a href='/privacypolicy?from=register' style={{ marginLeft:5 }}>
                                {t.text.checkAgree.pvc}
                            </a> 
                           </span>
                        </label>
                        {translate === 'id' ? <img src={GROUPID} /> : <img src={GROUPEN} />}
                    </div>
                </div>

            </div>
            {mobile ? <></> : <div style={{
                float: 'right', width: '50%', height: '100vh',
                backgroundImage: "url(" + BG + ")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'fixed',
                right: 0
            }}>
                <img src={SPLASH} style={{ right: 0, bottom: 0, position: "fixed" }} />
            </div>}
        </div>
    )
}

export default Home