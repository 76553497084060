const Id = {
    // title
    title : {
        agreement: 'PERJANJIAN DAN PERSETUJUAN MERCHANT',
        regis: 'PENDAFTARAN MERCHANT',
        regok: 'PENDAFTARAN BERHASIL',
        addok: 'BERHASIL MENAMBAHKAN PRODUK',
        editok: 'BERHASIL MENGUBAH PRODUK',
        product_add: 'TAMBAH PRODUK',
        product_edit: 'UBAH PRODUK',
        landing: `Beli barang yang kamu mau, kami antarkan!`,
        landingContent: 'Kenapa Pilih Kami?',
        openingHour: 'JAM BUKA TUTUP',
        modalAdd:'Tambah',
        modalEdit:'Ubah'
    },
    
    // subtitle
    subTitle : {
        regis: 'MULAI JUAL PRODUK ANDA MELALUI MAXIM ORDER SERVICE',
        agreement: 'BACA DENGAN SEKSAMA SEBELUM MELANJUTKAN', 
        detail: 'DETAIL TOKO',
        location: 'DETAIL LOKASI',
        pic: 'MERCHANT PICTURE',
        regok:'Terima Kasih sudah bergabung dan mohon tunggu verifikasi dari kami, anda bisa mengakses aplikasi melalui link berikut',
        addok:'Produk berhasil di tambah. Anda bisa tambah lagi atau bisa menutup halaman ini',
        editok:'Produk berhasil di ubah. Anda bisa menutup halaman ini',
        landing:'Harga produk wajar dengan pengiriman terjangkau',
    },
    
    // Form
    form : {
        name: 'Nama Toko',
        desc: 'Deskripsi',
        email: 'Email',
        password: 'Password',
        passconf: 'Konfirmasi Password',
        phonenumber: 'Nomor Telepon',
        address: 'Alamat Toko',
        city: 'Kota',
        district: 'Kecamatan',
        upload: 'Unggah Foto Toko',
        product_name: 'Nama Produk',
        product_category: 'Kategori',
        product_price: 'Harga',
        product_disc: 'Diskon',
        product_discamnt: 'Jumlah Diskon',
        product_rec: 'Disarankan',
        day: 'Hari',
        timeOpen: 'Jam Buka',
        timeClose: 'Jam Tutup',
    },
    
    // note
    note : {
        map: 'Perbesar peta untuk lokasi yang lebih akurat, dan ketuk/klik dua kali untuk lokasi terikini',
    },
    
    // button
    btn: {
        Register: 'Daftar Sekarang',
        Language: 'Indonesia',
        Next: 'Lanjutkan',
        Submit: 'Kirim',
        Back: 'Kembali',
        Acc: 'Setuju',
        Pic: 'Pilih Foto',
        Close: 'Tutup',
        Addmore: 'Tambah Lagi',
        toRegist: 'Menjadi Partner Kami',
        schedule: 'Tambah Jadwal Baru',
        edit: 'Ubah',
        delete:'Hapus'
    },
    
    // text
    text: {
        welcome: 'Halo Selamat Datang di Maxim Merchant',
        checkAgree: {
            agree: 'Anda setuju dengan',
            and: 'dan',
            merchAgree: 'Perjanjian Merchant',
            pvc: 'Kebijakan Privasi'
        },
       
        landing_content : {
            title : {
                deliver: 'Murah dan Pengiriman Cepat',
                price: 'Harga sama dengan di toko'
            },
            subTitle: {
                deliver:'Kami menyediakan layanan pengiriman yang murah dan cepat untuk memastikan klien bisa mendapatkan pengalaman berkualitas baik dengan layanan berbiaya rendah.',
                price:'Kami ingin memberikan ekosistem bisnis yang adil untuk semua, kami memberikan mitra pedagang kami bebas komisi untuk memastikan pelanggan bisa mendapatkan harga yang sama seperti saat membeli langsung ke toko'
            }
        },
        landingFooter : {
            app: 'Pesan dengan Maxim',
            contact: 'Kontak Kami'
        },
        Tutorial_content : [
            {
                title: 'Pilih Fitur Food di Aplikasi Maxim Anda',
                desc: [
                    'Buka Aplikasi Maxim Anda ',
                    `Pilih Fitur Food kemudian pilih “Foods&Goods”`,
                    `Masukan destinasi Lokasi`,
                    `Klik “Apa yang harus dibeli” atau tombol Memesan`
                ]
            },
            {
                title: 'Cari dan lihat Toko atau barang yang ingin anda beli barangnya',
                desc: [
                    `Gunakan fitur pencarian untuk mencari toko atau produk yang ingin anda beli`,
                    `Pilih “Terdekat” untuk mengurutkan berdasarkan jarak mulai dari toko terdekat di lokasi anda.`,
                    `Atau pilih “Menurut Lokasi” untuk lihat toko berdasarkan lokasi.`,
                ]
            },
            {
                title: 'Beli produk yang Anda inginkan dan konfirmasi pesanan',
                desc: [
                    `Klik “Tambah” untuk menaruh produk yang ingin kamu beli ke keranjang`,
                    'Klik “Lihat Keranjang” setelah selesai memilih produk.',
                    `Kemudian “Konfirmasi Pesanan Kamu”`,
                ]
            },
            {
                title: 'Cek Order status dan lacak pesanan anda',
                desc: [
                    `Lihat status orderan anda dan respon dari Toko`,
                    `Klik “Telusuri status pengantaran” untuk melihat progress pengiriman barang anda`
                ]
            },
            {
                title: 'Driver kami akan ambil dan antar barang yang kamu beli',
                desc: [
                    `Driver kami akan membawakan pesanan denagn cepat, murah, dan aman.`
                ]
            }
        ]
    },
    err: {
        notfilled: `Formulir tidak bisa kosong`,
        notemail: `Alamat Email tidak benar`,
        notpass: `Password tidak kuat, minimal 7 huruf dan coba kombinasikan huruf kecil, huruf besar, angka, spesial karakter (!@#$%^&*-=_+)`,
        passnotsame: 'Password and Konfirmasi Password harus sama',
        noloc: 'Pilih lokasi anda di peta (klik/ketuk peta dua kali untuk lokasi anda sekarang atau pindahkan pointer ke lokasi toko anda)' ,
        nopic: 'Pilih foto untuk di unggah',
        sent: 'Error terjadi kesalahan saat pengiriman data ke server',
        emailsent: 'Email sudah terdaftar',
        picFail: `Tidak bisa upload foto, silahkan coba pilih foto lain`,
        nodata: 'Tidak ada data',
        get: 'Error Saat Mengambil Data'
    },
    option : {
        yes: "Ya",
        no: "Tidak"
    },
    day :{
        sunday: 'Minggu',
        monday: 'Senin',
        tuesday: 'Selasa',
        wednesday: 'Rabu',
        thursday: 'Kamis',
        friday: 'Jumat',
        saturday: 'Sabtu',
    }
}

export default Id