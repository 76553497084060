const Ch = {
    // title
    title: {
        agreement: '商家协议',
        regis: '商家注册',
        regok: '注册成功',
        addok: '添加产品成功',
        editok: '编辑产品成功',
        product_add: '添加产品',
        product_edit: '编辑产品',
        landing: `买你想要的东西，我们将提供！`,
        landingContent: '为什么选择我们？',
        openingHour: '营业时间',
        modalAdd:'添加',
        modalEdit:'编辑'
    },

    // subtitle
    subTitle: {
        regis: '通过Maxim订单服务开始销售您的产品',
        agreement: '请在继续之前仔细阅读协议',
        detail: '商人细节',
        location: '位置详情',
        pic: '商人图片',
        regok: '感谢您加入Maxim商家，请等待我们的验证。您可以通过下面的链接访问应用程序',
        addok: '产品已添加。您可以添加更多或者您可以关闭此页面',
        editok: '产品编辑。您可以关闭此页面',
        landing: '公平产品价格与经济实惠的交货',
    },

    // Form
    form: {
        name: '商店名称',
        desc: '描述',
        email: '电子邮件',
        password: '密码',
        passconf: '确认密码',
        phonenumber: '电话号码',
        address: '地址',
        city: '城市',
        district: '地区',
        upload: '上传商店图片',
        product_name: '产品名称',
        product_category: '类别',
        product_price: '价钱',
        product_disc: '折扣',
        product_discamnt: '折扣金额',
        product_rec: '推荐的',
        day: '天',
        timeOpen: '开放时间',
        timeClose: '时间关闭',
    },

    // note
    note: {
        map: '放大地图有关更准确的位置，然后点击/点击当前位置',
    },

    // button
    btn: {
        Register: '现在注册',
        Language: '英语',
        Next: '继续',
        Submit: '提交',
        Back: '后退',
        Acc: '同意',
        Pic: '选择照片',
        Close: '关闭',
        Addmore: '添加更多',
        toRegist: '成为我们的合作伙伴',
        schedule: '添加新时间表',
        edit: '编辑',
        delete:'删除'
    },

    // text
    text: {
        welcome: '你好欢迎来到Maxim商人',
        checkAgree: {
            agree: '你同意',
            and: '和',
            merchAgree: '商户协议',
            pvc: '隐私政策'
        },
        
        landing_content: {
            title: {
                deliver: '便宜和快速的交付',
                price: '价格与商店相同'
            },
            subTitle: {
                deliver: '我们提供便宜和快速的送货服务，以确保客户可以在低成本服务中获得优质的质量体验。',
                price: '我们希望为所有人提供公平的商业生态系统，我们为我们的商家合作伙伴致意，不确定客户可以获得价格与直接购买的价格相同'
            }
        },
        landingFooter: {
            app: '用maxim.',
            contact: '联系我们'
        },
        Tutorial_content: [
            {
                title: '在Maxim应用程序上选择市场服务',
                desc: [
                    '打开Maxim应用程序',
                    `选择市场服务，选择“食品和商品”`,
                    `把目的地位置放置`,
                    `点击“购买什么”或订单按钮`
                ]
            },
            {
                title: '浏览并找到您想要购买的商家',
                desc: [
                    `使用搜索功能来搜索您要购买的商家或产品`,
                    `选择“靠近我”以按距离从最近的位置开始对其进行排序。`,
                    `或选择“按地区”寻找特定区的商家。`,
                ]
            },
            {
                title: '购买所需产品并确认订单',
                desc: [
                    `单击“添加”以将您想要购买的产品放入购物车`,
                    '完成选择产品后单击“查看我的购物车”。',
                    `然后“确认您的订单” ”`,
                ]
            },
            {
                title: '检查您的订单状态并跟踪您的订单',
                desc: [
                    `查看订单的状态以及商家的响应`,
                    `单击“检查交货状态”以查看传递进度`
                ]
            },
            {
                title: '我们的司机将选择并提供购买。',
                desc: [
                    `我们的司机将以便宜，良好快捷的服务带来您的订单。`
                ]
            }
        ],
    },
    err: {
        notfilled: `表格不能空`,
        notemail: `电子邮件地址无效`,
        notpass: `密码不强，最小7个字符，并尝试组合大写，小写，号码和特殊字符（！@＃$％^＆* _ + =  - ）`,
        passnotsame: '密码和密码确认必须相同',
        noloc: '从地图中选择您的位置（单击地图两次以获取当前位置或将指针移动到您的位置）',
        nopic: '选择照片上传',
        sent: '将数据发送到服务器时出错',
        emailsent: '已注册的电子邮件',
        picFail: `无法上传图像，请使用另一张照片`,
        nodata: '没有数据',
        get: '获取数据时出错'
    },
    option: {
        yes: "是的",
        no: "不"
    },
    day :{
        sunday: '星期日',
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期四',
        saturday: '周六',
    }

}

export default Ch