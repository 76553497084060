const En = {
    agreement: [
        'I.	Definition: ',
        'II. Context of Agreement: ',
        'III. Fee and Taxes: ',
        'IV. Rights and Obligations of the Merchant Partner: ',
        'V.	Rights and Obligations of MAXIM: ',
        'VI. Guarantee: ',
        'VII. Confidentiality: ',
        'VIII. Intellectual Property: ',
        'IX. Force Majeure: ',
    ],
    agreement_content: {
        1: [
            '1. “The Agreement” shall mean this document, that specifies the manner of providing the right to use the Merchant App, which contains up-to-date information on existing demand for certain types and quantity of food and beverages and/or groceries ordered by the Client(s).',
            `2.	“MAXIM” in this context shall mean PT Teknologi Perdana Indonesia, its respective Parent Company, or other particular party(ies) appointed by PT Teknologi Perdana Indonesia and/or its respective Parent Company which are legal entities granting the rights to the Merchant Partner to use the Merchant App according to this agreement, and receiving, processing and forwarding the Client's order to the Merchant Partner and informing the Client about the follow-up status of the Order`,
            '3.	“Merchant Partner” shall mean a person or an entity, who has expressed his/her/its submission to the agreement and has received the right to use the Merchant App, and therefore shall enjoy the rights given and fulfill the responsibilities as stipulated in this agreement hereunder',
            '4.	“Client” shall mean a person placing the Order for certain types and quantity of food and beverages and/or groceries via MAXIM',
            '5.	“Order” shall mean information on demand from the Client containing  certain types and quantity of food and beverages and/or groceries to be provided by Merchant Partner',
            '6.	“Merchant App” shall mean computer software installed on the Merchant Partner’s mobile phone, designated to give a remote access to the Order Database to the Merchant Partner.',
            '7.	“Mobile app” shall mean the computer software installed on the Client’s mobile phone and integrated into MAXIM software-information complex, allowing to computerize the Orders generation process via the Internet',
            '8.	“Driver” shall mean MAXIM’s Partners whom are responsible to convey the food and beverages and/or groceries ordered by the Client(s)',
            '9.	“Personal account” shall mean an account where advance payment of the Merchant Partner and amounts of cash deducted from these payments as payment for granting access to use the Merchant App. The personal account has a unique number generated by MAXIM.',
            '10. “Personal account balance” shall mean the difference as of certain time-point between the amount of cash deposited on the personal account and deducted from the personal account till this time-point'
        ],
        2: ['MAXIM and the Merchant Partner hereby expressing their submission to the agreement and their willingness to cooperate in the context of providing food and beverages and/or groceries to fulfill the needs of the Client through their orders from the Mobile App'],
        3: [
            '1.	For every order, MAXIM is entitled to receive a fee payable with the amount calculated by multiplying a certain percentage of the total price of the order before taxes and Merchant Partner’s service charge, which will be charged to the Merchant Partner directly from his/her/its personal account balance after completing the order',
            '2.	During the promotional period where the fee payable is 0% (zero percent), the Merchant Partner shall not stipulate higher prices for the food and beverages and/or groceries than the normal prices (prices for direct purchasing from the Merchant Partner’s shop/restaurant)',
            '3.	The Merchant Partner shall inform MAXIM should he/she/it will add taxes and service charge on the components of the order price',
            '4.	MAXIM is entitled to change the percentage of the fee payable at any time, with or without prior notification to the Merchant Partner',
            '5.	The responsibilities of each party to the tax law of Republic of Indonesia shall be fulfilled by the respective party. One Party shall not bear any responsibility related to the tax regulation of the other party'
        ],
        4: [
            '1.	Merchant Partner is entitled to:',
            'a.	Use the Merchant App and its features for its original purposes',
            'b.	Promote his/her/its shop/restaurants and the product independently through the available media',
            'c.	Cooperate with other parties without disrupting his/her/its obligations under this agreement',
            'd.	Get technical support from MAXIM if necessary',
            '2.	Merchant Partner shall',
            'a.	Complete the order in effective and timely manner',
            'b.	Provide the product list along with the price list to MAXIM.',
            'c.	Provide clear address and location point (based on the map) of the shop/restaurant.',
            'd.	Ensure that his/her/its shop/restaurant is accessible and can be identified by the Driver.',
            'e.	Fulfill his/her/its legal obligations to run his/her/its business in Indonesia.',
            'f.	Provide other relevant data, including but not limited to personal data or company data to MAXIM if necessary.',
            'g.	Check for any update(s) or amendment(s) on the agreement by his/her/its own volition to ensure he/she/it is well informed about such changes',
            '3.	Merchant Partner shall not:',
            'a.	Provide false information about the product(s) and the price(s). Actions such as selling products with specifications or prices different from the ones stated in the Merchant App and the Mobile App shall be determined as violations of this provision.',
            'b.	Sell product with bad quality, expired product, and product with other similar conditions which might cause inconvenience or endanger the health of the Client.',
            'c.	Sell goods deemed illegal under the law of Indonesia.',
            'd.	Interact or communicate with the Client or the Driver in an impolite, rude, or unprofessional way.',
            'e.	Try to interact or communicate with the Client or the Driver for other causes than completing the order.',
            'f.	Use the Merchant App for the purposes other than the original ones',
            'g.	Do any kind of action as an attempt to hack or modify the Merchant App and the systems tied to it, or to disrupt the performance of the Merchant App and the systems tied to it'
        ],
        5: [
            '1.	MAXIM is entitled to:',
            'a.	Amend the content of this agreement at any time and set the effective date of the amendment at its convenience.',
            'b.	Receive the fee payable as stipulated in Article III paragraph 1 of this agreement.',
            'c.	Suspend or revoke the access of the Merchant Partner to the Merchant App if the Merchant Partner does any violation to the obligation as stipulated in Article IV of this agreement, or if MAXIM determines that the Merchant Partner is no longer qualified to provide the service.',
            'd.	Ask for data from the Merchant Partner if deemed necessary.',
            '2.	MAXIM shall:',
            'a.	Ensure that the Merchant App and the systems tied to it are running well and can be used for its original purposes.',
            'b.	Provide technical support for the Merchant Partner if deemed necessary.',
        ],
        6: [
            'Each Party represents and guarantees the following:',
            'a.	This agreement is agreed by the competent authority on behalf of MAXIM and the Merchant Partner in accordance with the provisions of the Articles of Association of each of the Parties.',
            'b.	The Merchant Partner has fulfilled every legal requirement and provision in carrying out this agreement.',
            'c.	MAXIM hereby guarantees that the holder of the “Maxim” brand license or business belongs to MAXIM, and MAXIM hereby releases the Merchant Partner if there is a claim by another party regarding ownership of that brand or business.',
            'd.	Neither Party is involved in a civil or criminal case, and each party will release the other party from any claims from third parties caused by the actions of MAXIM or the Merchant Partner in connection with this agreement',
        ],
        7: [
            '1.	The Parties hereby agree to maintain the confidentiality of all information obtained by each Party from the other Party, which includes but is not limited to information on sales, the process of supplying, the identity of the Parties, and other information obtained by each Party in order to implement this Agreement which is trade secret or other information that the Parties have agreed to keep confidential.',
            '2.	The obligation to maintain the confidentiality of such information as referred to in paragraph (1) of this Article shall apply both for the duration of this Agreement and after its termination.',
            '3.	The provisions referred to in paragraph (1) and paragraph (2) of this Article can be overridden in the event that the disclosure of the information must be carried out in the framework of law enforcement carried out by the competent authority based on the prevailing laws and regulations.'
        ],
        8: [
            '1.	Both Parties shall be taking care of and  respectful towards all intellectual property rights owned or held by the parties, or other intellectual property rights emerging due to the implementation of this Agreement in accordance with the requirements and regulations of the current law.',
            '2.	In terms of promotion, Both Parties allow each other to use the logos and trademarks for their advertisement designs and publications, without disregarding either the internal regulations of Both Parties or the requirements and regulations of the current law.',
            '3.	This agreement shall not regulate any handover or transferal of exclusive rights on Intellectual Property of Each Parties, with an exception of the usage of logos and trademarks for advertising activities as stipulated in paragraph (2) above'
        ],
        9: [
            '1.	Failure to implement or delay in the implementation of part or all of the provisions of this Agreement by either Party or the Parties is not considered a violation of the Agreement if it is caused by force majeure.',
            '2.	Included as force majeure are occurrences which with all their power and effort cannot be predicted and cannot be overcome by the Party experiencing them.',
            '3.	The Party that is unable to fulfill its obligations in connection with the force majeure must notify the other Party in writing no later than 14 (fourteen) working days from the start of the incident. ',
            '4.	The negligence or delay of the Party affected by force majeure in notifying as referred to in this Article may result in the event being not recognized as force majeure'
        ],
    },
}

export default En