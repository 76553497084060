import React from 'react'
import { t } from '../../misc/utils'

// Component
import { PhoneComponent, TopNavLandingComponent } from '..'
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { useMediaQuery } from 'react-responsive'

// assets
import background from '../../assets/image/backlnd1.png'
import logo_white from '../../assets/image/logo-white-maxim1.svg'
import imageContent1 from '../../assets/image/contentimage1.png'
import imageContent2 from '../../assets/image/contentimage2.png'
import Android from '../../assets/image/android_play_store.png'
import Ios from '../../assets/image/ios_appstore.png'
import Huawei from '../../assets/image/huawei_app_galery.png'

const LandingPageMobile = () => {
    const translate = localStorage.getItem('language')
    const mobile = useMediaQuery({
        query: '(max-width:990px)'
    })
    const logoStore = [Ios, Android, Huawei]
    const handleLogo = (type) => {
        if (type === Android) {
            window.open('https://play.google.com/store/apps/details?id=com.taxsee.taxsee&referrer=utm_source%3Dtaximaxim%26utm_medium%3Dsite', '_blank')
        }
        else if (type === Ios) {
            window.open('https://apps.apple.com/app/id579985456', '_blank')
        }
        else {
            window.open('https://appgallery.huawei.com/app/C100093035?appId=C100093035&source=appshare&subsource=C100093035', '_blank')
        }
    }
    const imageMobile = "https://delivery.taximaxim.com/themes/delivery/img/header/410_mirror.jpg"
    const [activeKey, setActiveKey] = React.useState(0)
    return (
        <div style={{
           
        }}>
            <div style={mobile ? {
                backgroundImage: "url(" + imageMobile + ")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '100%',
            } : {
                backgroundImage: "url(" + background + ")",
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',

                width: '100%',
            }}>
                <TopNavLandingComponent pic={logo_white} translate={translate} />
                <div style={{ padding: 10, color: 'white', marginTop: 50, padding: 20 }}>
                    <h1 style={{ fontWeight: 500, fontSize: 42 }}>{t.title.landing}</h1>
                </div>
                <div style={{ padding: 10, color: 'white', paddingLeft: 20 }}>
                    <p style={{ fontWeight: 400, fontSize: 25 }}>{t.subTitle.landing} </p>

                </div>
            </div>
            <div style={{ marginTop: 10, marginBottom: 100, margin: 'auto', padding: 20 }}>
                <h1 style={{ fontWeight: 700, fontSize: 35, marginBottom: 50 }}>{t.title.landingContent}</h1>
                <div>
                    <div style={{ height: "50%", marginBottom: 50 }}>
                        <img src={imageContent1} style={{ height: 120, width: 120, marginBottom: 10 }} />
                        <div >
                            <h3 style={{ fontWeight: 700, fontSize: 28 }}>{t.text.landing_content.title.deliver}</h3>
                            <p style={{ fontWeight: 400, fontSize: 20 }}>{t.text.landing_content.subTitle.deliver}</p>
                        </div>
                    </div>
                    <div style={{ height: "50%"  }}>
                        <div style={{ marginBottom: 10  }}>
                            <img src={imageContent2} style={{ height: 120, width: 120, display:'block', marginLeft:'auto' }} />

                        </div>
                        <div>
                            <h3 style={{ textAlign: 'end', fontWeight: 700, fontSize: 28 }}>{t.text.landing_content.title.price}</h3>
                            <p style={{ textAlign: 'end', fontWeight: 400, fontSize: 20 }}>{t.text.landing_content.subTitle.price}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: 50, padding: 20 }}>
                    {mobile
                        &&
                        <div style={{ margin: 'auto', }}>
                            <PhoneComponent changesButton={setActiveKey} activeKey={activeKey} translate={translate} />
                        </div>
                    }
                    <div style={{ width: '100%', padding: 10, marginTop: 50 }}>
                        {t.text.Tutorial_content.map((item, key) => {
                            return (
                                <Accordion expanded={activeKey === key} onClick={() => setActiveKey(key)} style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20, marginBottom: 10 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={activeKey === key ? { backgroundColor: '#FF6000', color: 'white', borderRadius: 10 } : { backgroundColor: '#EFEFEF', color: 'black', borderRadius: 10 }}
                                    >
                                        <Typography style={{ fontSize: 25, fontWeight: 500 }}>
                                            <ul>
                                                <li>{item.title}</li>
                                            </ul>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        style={{ borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderColor: '#FF6000', border: '1px solid #FF6000', marginTop: -10 }}
                                    >
                                        <Typography style={{ fontSize: 18, borderColor: '#FF6000' }}>
                                            <ul>
                                                {item.desc.map((text) => {
                                                    return (
                                                        <li>{text}</li>
                                                    )
                                                })}
                                            </ul>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                            )
                        })}
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: 'black', height: '100%', padding: 20, display: 'flex', flexDirection: 'column', marginTop: 50 }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '50%', marginBottom: 40, }}>
                    <h1 style={{ color: 'white', marginBottom: 40, fontWeight: 500, fontSize: 30 }}>{t.text.landingFooter.app}</h1>
                    {logoStore.map((item) => {
                        return (
                            <img onClick={() => handleLogo(item)} src={item} style={{ marginTop: 10, marginBottom: 10, width: 200 }} />
                        )
                    })}
                </div>
                <div>
                    <h1 style={{ color: 'white', marginBottom: 40, fontWeight: 500, fontSize: 30 }}>{t.text.landingFooter.contact}</h1>
                    <p style={{ color: 'white', fontWeight: 400, fontSize: 20 }}>Email : idmarketplace@taximaxim.com</p>
                    <p style={{ color: 'white', fontWeight: 400, fontSize: 20 }}>Home : <a href='https://www.taximaxim.com' style={{ textDecoration:'none', color:'white' }}>www.taximaxim.com</a></p>
                </div>

            </div>

        </div>
    )
}

export default LandingPageMobile