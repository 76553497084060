import axios from 'axios'
const URL = 'https://brmarket-api.taximaxim.com/api/v1/merchant'
// const URL = 'http://localhost:8001/api/v1/merchant'

export const DataMerchantDetail = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'DATADETAIL',
                payload: data
            })
        }
        catch (err) {
            // console.log(err)
        }
    }
}

export const LocationMerchantDetail = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: 'LOCTDETAIL',
                payload: data
            })
        }
        catch (err) {
            // console.log(err)
        }
    }
}

export const PositionMerchant = (data) => {
    return async (dispatch) => {
        dispatch({
            type:'POSITION',
            payload: data
        })
    }
}

export const getCity = () => {
    return async (dispatch) => {
        try {
            // let res = await axios.get('http://localhost:8001/api/v1/cd')
            let res = await axios.get(' https://brmarket-api.taximaxim.com/api/v1/cd')
            dispatch({
                type: 'DATA_CITIES',
                payload: res.data
            })
        } catch (err) {
            // console.log(err)
        }

    }
}

export const getDistrict = (cities) => {
    return async (dispatch) => {
        try {
            // let res = await axios.get(`http://localhost:8001/api/v1/cd?city=${cities ? cities : 1}`)
            let res = await axios.get(`https://brmarket-api.taximaxim.com/api/v1/cd?city=${cities ? cities : 1}`)
            dispatch({
                type: `DATA_DISTRICT`,
                payload: res.data[0].district
            })
        }
        catch (err) {
            // console.log(err.response)
        }
    }
}

export const RegisterMerchant = (data) => {
    return async (dispatch) => {
        try {
            const bodyFormData = new FormData()
            Object.keys(data).forEach((key) => {
                if (key === 'image') {
                    bodyFormData.append('image', data.image)
                }
                else bodyFormData.append(key, data[key])
            })

            const res = await axios.post(`${URL}/register`, bodyFormData, {
                'Content-Type': `multipart/form-data; boundary=${bodyFormData._boundary}; charset=UTF-8`,
            });
            if(res.data){
                dispatch({
                    type:'COMPLETE'
                })
            }

            // NOTE: Base url for merchant API => https://brmarket-api.taximaxim.com/api/v1/merchant

        } catch (err) {
            const lengthRes = Object.keys(err.response.data).length
            if(lengthRes === 1) {
                dispatch({
                    type:'RES_REG',
                    payload: err.response.data.email[0]
                })
            }
            else{
                dispatch({
                    type:'RES_REG',
                    payload: 'Error 404'
                })
            }
        }
    }
}


export const backPage = () => {
    return async(dispatch) => {
        try{
            dispatch({
                type:'BACKBUTTON'
            })
        }
        catch(err){
            // console.log(err)
        }
    }
}

export const removeRes = () => {
    return async(dispatch) => {
        try{
            dispatch({
                type:"REMOVERES"
            })
        }
        catch(err){
            // console.log(err)
        }
    }
}