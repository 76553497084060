import React from 'react'
import { Form } from "react-bootstrap"
import { Redirect } from 'react-router'
import { t } from '../../../misc/utils'

const Language = ({value, style}) => {
    return (
        <Form.Select value={value} style={style}
            onChange={(e) => {
                localStorage.setItem('language', e.target.value)
                window.location.reload()
            }} title='select Language' >
            <option value="en" >EN</option>
            <option value="es" >ES</option>
            <option value="id" >ID</option>
            <option value="my" >MY</option>
            <option value="ph" >PH</option>
            <option value="pt" >BR</option>
            <option value="th" >TH</option>
            <option value="ch" >CHN</option>
        </Form.Select>
    )
}

export default Language