const INITIAL_STATE = {
    item : [],
    category: [],
    errors_message: '',
    itemEdit: null
}

const merchantReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case 'FETCHCATEGORY' :
            return{
                ...state,
                category: action.payload
            }
        case 'ERRFETCH' : 
            return{
                ...state,
                errors_message:action.payload
            }
        case 'REMOVE_ERR_FETCH' : 
            return{
                ...state,
                errors_message: ''
            }
        default : 
            return state
    }
}

export default merchantReducer