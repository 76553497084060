import {Switch, Route} from 'react-router-dom'

// import pages
import {
  Home,
  HomeRegister,
  MerchantAgreement,
  Register,
  CompleteAdd,
  ProductAdd,
  ProductEdit,
  CompleteEdit,
  PrivacyPolicy,
  Schedule
} from '../../screen'


function Routers() {
  const reload = () => window.location.reload()
  return (
    <div>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/register' component={HomeRegister} exact />
        <Route path='/merchant-agreement' component={MerchantAgreement} />
        <Route path='/tnc' component={MerchantAgreement} />
        <Route path='/privacypolicy' component={PrivacyPolicy} />
        <Route path='/register/reg' component={Register} />

        {/* Merchant Route */}
        <Route path='/additem' component={ProductAdd} />
        <Route path='/edititem' component={ProductEdit} />
        <Route path='/complete_add' component={CompleteAdd} />
        <Route path='/complete_edit' component={CompleteEdit}/>
        <Route path='/schedule-open' component={Schedule}/>
        {/* Special Route */}
        <Route path="/app-ads.txt" onEnter={reload} />
      </Switch>
    </div>
  );
}

export default Routers;